import Vue from 'vue'
import router from './router'
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import {i18n} from "./plugins/i18n";
import vSelect from 'vue-select'
import Notifications from 'vue-notification'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faBell,
    faBellSlash,
    faCheck,
    faClone,
    faDownload,
    faEdit,
    faExclamationTriangle,
    faEye,
    faPencilAlt,
    faPlusCircle,
    faSave,
    faSearch,
    faTrash,
    faUserSecret,
    faUpload
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import App from './App'
import Multiselect from 'vue-multiselect'

library.add(faUserSecret, faEdit, faPlusCircle, faTrash, faSave, faEye, faSearch, faUpload, faBell, faCheck, faBellSlash, faPencilAlt, faExclamationTriangle, faDownload, faClone);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Notifications)
Vue.component('multiselect', Multiselect);
Vue.component('v-select', vSelect);

new Vue({
//define the selector for the root component
    el: '#app',
    //pass the template to the root component
    template: '<App/>',
    //declare components that the root component can access
    components: {App, Multiselect},
    //pass in the router to the Vue instance
    router,
    i18n
}).$mount('#app');
