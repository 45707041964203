<template>
    <login-layout>
        <b-alert :show="true" variant="primary">
            Registration success. Try to login now
            <hr/>
            <router-link class="btn btn-link btn-block" to="/login">Login</router-link>
        </b-alert>
    </login-layout>
</template>

<script>
    import LoginLayout from '../layouts/LoginLayout'

    export default {
        components: {
            LoginLayout
        },
    }
</script>