<style scoped>
/**/
</style>

<template>
  <main-layout>
    <div class="container" style="width: 100%; margin-top: 20px; margin-bottom: 30px">
      <div v-if="model">
        <div class="form-group">
          <label>Creative name (Internal)</label>
          <b-form-input
              v-model="model.creative_name"
              placeholder="Enter creative headline"
              :state="errors.creative_name ? errors.creative_name.state : null"
          ></b-form-input>
          <b-form-invalid-feedback v-if="errors.creative_name">{{ errors.creative_name.message }}</b-form-invalid-feedback>
        </div>

        <div class="form-group">
          <label>Creative headline</label>
          <b-form-input
              v-model="model.name"
              placeholder="Enter creative headline"
              :state="errors.name ? errors.name.state : null"
          ></b-form-input>
          <b-form-invalid-feedback v-if="errors.name">{{ errors.name.message }}</b-form-invalid-feedback>
        </div>
        <div class="form-group">
          <label>Description</label>
          <b-form-textarea v-model="model.description"
                           placeholder="Enter creative description"
                           rows="3"
                           max-rows="6"
                           class="mb-2"
                           :state="errors.description ? errors.description.state : null"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="errors.description">{{ errors.description.message }}</b-form-invalid-feedback>
        </div>

        <div class="form-group">
          <b-form-file
              class="form-control"
              :state="errors.icon ? errors.icon.state : null"
              v-model="files.icon"
              accept=".png, .jpg, .jpeg"
          />
          <div v-if="model.icon">
            <img alt="Icon" :src="model.icon" style="max-width: 10%; margin-top: 10px;"/>
          </div>
        </div>

        <div class="form-group">
          <div style="display: flex; justify-content: left; gap: 5px;">
            <b-button variant="success" @click="save">
              <font-awesome-icon icon="save"></font-awesome-icon>
              Save
            </b-button>
            <div>
              <b-button variant="warning" @click="exit">Exit</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import API from "../../api/Api";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  name: "CreativeForm",
  components: {
    MainLayout,
  },

  watch: {
    files: {
      handler() {
        for (const name in this.files) {
          if (this.files[name] instanceof File) {
            // Get base64 from files[name] File object
            const reader = new FileReader();
            reader.readAsDataURL(this.files[name]);
            reader.onload = () => {
              const model = {...this.model};
              model[name] = reader.result;
              this.model = model;
            };
          }
        }
      },
      deep: true
    }
  },

  data() {
    return {
      model: {
        creative_name: null,
        name: null,
        description: null,
        icon: null
      },
      errors: [],
      files: {
        icon: null
      }
    };
  },

  destroyed() {
    document.removeEventListener('keydown', this.saveEventHandler);
  },

  mounted() {
    // Capture ctrl+s
    document.addEventListener('keydown', this.saveEventHandler);

    if (this.$route.params.id === 'new') {
      this.model = {};
    } else {
      API.get(`/creatives/${this.$route.params.id}`).then(response => {
        if (response.status !== 200) {
          // Show error alert
          this.$notify({
            title: 'Failed',
            text: `Unable to load creative. Status code: ${response.status}`,
            group: 'app',
            type: 'error'
          });
        }

        this.model = response.data;
      }).catch(e => {
        // Show error alert
        this.$notify({
          title: 'Failed',
          text: `Unable to load creative. Status code: ${e.response.status}`,
          group: 'app',
          type: 'error'
        });
      })
    }
  },

  methods: {
    save() {
      this.errors = {};

      const method = this.model._id ? API.put : API.post;

      method(`/creatives${this.model._id ? '/' + this.model._id : ''}`, this.model).then(result => {
        if (result.status === 200 || result.status === 201) {
          this.$notify({
            title: 'Creative saved',
            text: `Creative ${this.model.name} was saved successfully`,
            group: 'app',
            type: 'success'
          });

          if (result.data._id) {
            this.model._id = result.data._id;
          }
        } else {
          this.$notify({
            title: 'Creative save failed',
            text: `Creative ${this.model.name} was not saved`,
            group: 'app',
            type: 'error'
          });
        }
      }).catch(e => {
        if (e.response && e.response.status === 422) {
          this.processErrors(e.response);
        }

        this.$notify({
          title: 'Creative save failed',
          text: `Creative ${this.model.name} was not saved. Error: ${e.message}`,
          group: 'app',
          type: 'error'
        });
      })
    },

    processErrors(result) {
      let errors = {};
      for (const error of result.data) {
        errors[error.param] = {state: false, message: error.msg};
      }

      this.errors = errors
    },

    exit() {
      if (confirm('Are you sure you want to exit? All unsaved changes will be lost.')) {
        this.$router.push({path: '/creatives'});
      }
    },

    saveEventHandler(e) {
      if (e.key === 's' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        this.save();
      }
    }
  }
}
</script>
