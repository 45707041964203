<style scoped>
/**/
</style>

<template>
    <main-layout>
        <div class="container" style="width: 100%; margin-top: 20px">
            <div class="filter-form" v-if="showFilters">
                <div class="form-row">
                    <div class="form-group col-md-3" v-for="(value, filter) in filters" v-bind:key="filter">
                        <label>{{ filterLabels[filter] }}</label>

                        <div v-if="DDL[filter]">
                            <b-select v-model="filters[filter]" :options="[ {value: null, text: 'Any'},].concat(DDL[filter])"/>
                        </div>
                        <div v-else>
                            <b-input type="text" @keydown="e => e.key === 'Enter' && loadData()" v-model="filters[filter]"/>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: left; gap: 5px;">
                    <b-button @click="resetFilter" variant="warning" type="button">Reset filter</b-button>
                    <b-button @click="showFilters = false" variant="info" type="button">Hide filters</b-button>
                    <b-button @click="loadData" variant="primary" type="button">Filter</b-button>
                </div>
            </div>
            <div class="form-row" v-if="!showFilters">
                <button class="btn btn-info" @click="showFilters = true">Show filters</button>
            </div>
            <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
                <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
            </div>
            <router-link to="/campaigns/new">
                <button style="float: right" class="btn btn-success btn-sm">Add new campaign</button>
            </router-link>
            <b-table
                    style="margin-top: 20px; width: 100%; font-size: 14px"
                    :items="data"
                    :fields="tableFields"
                    striped
                    no-local-sorting
                    @sort-changed="sort"
            >
                <template v-slot:cell(sync_status)="row">
                    <font-awesome-icon v-if="!row.item.outOfSync" style="color: green" icon="check"></font-awesome-icon>
                    <font-awesome-icon v-else style="color: #fd7e14" icon="exclamation-triangle"></font-awesome-icon>
                    {{ row.item.synced }} / {{ row.item.synced + row.item.outOfSync }}
                </template>
                <template v-slot:cell(geo)="row">
                    {{ row.item.geo && row.item.geo.length ? row.item.geo.map(isoCode => isoCode.toUpperCase()).join(', ') : 'ALL' }}
                </template>
                <template v-slot:cell(devices)="row">
            <span v-if="row.item.devices && row.item.devices.length">
               <span v-for="k in row.item.devices" v-bind:key="k">{{ k.toUpperCase() }}<br/></span>
            </span>
                    <span v-else>ALL</span>
                </template>
                <template
                        v-slot:cell(browsers)="row">
            <span v-if="row.item.browsers && row.item.browsers.length">
               <span v-for="k in row.item.browsers" v-bind:key="k">{{ k.toUpperCase() }}<br/></span>
            </span>
                    <span v-else>ALL</span>
                </template>
                <template
                        v-slot:cell(languages)="row">
            <span v-if="row.item.languages && row.item.languages.length">
               <span v-for="k in row.item.languages" v-bind:key="k">{{ k.toUpperCase() }}<br/></span>
            </span>
                    <span v-else>ALL</span>
                </template>
                <template
                        v-slot:cell(connections)="row">
            <span v-if="row.item.connections && row.item.connections.length">
               <span v-for="k in row.item.connections" v-bind:key="k">{{ k.toUpperCase() }}<br/></span>
            </span>
                    <span v-else>ALL</span>
                </template>

                <template v-slot:cell(status)="row">
                    {{ row.item.status.toUpperCase() }}
                </template>

                <template v-slot:cell(name)="row">
                    <span :style="{color: row.item.status === 'archived' ? 'red' : ''}">{{ row.item.name }}</span>
                </template>

                <template v-slot:cell(actions)="row">
                    <div style="display: flex; justify-content: left; gap: 5px;">
<!--                        <div>-->
<!--                            <router-link :to="'/campaigns/publish/' + row.item._id">-->
<!--                                <button class="btn btn-info btn-sm">Publish campaigns</button>-->
<!--                            </router-link>-->
<!--                        </div>-->
                        <div>
                            <router-link :to="'/campaigns/' + row.item._id">
                                <button class="btn btn-primary btn-sm">Edit</button>
                            </router-link>
                        </div>
                        <div>
                            <button class="btn btn-danger btn-sm" @click="remove(row.item)">Delete</button>
                        </div>
                        <div>
                            <button class="btn btn-info btn-sm" @click="requestClone(row.item)">
                                <font-awesome-icon icon="clone"></font-awesome-icon>
                            </button>
                        </div>
                        <div>
                            <button class="btn btn-primary btn-sm" @click="downloadAsJson(row.item)">
                                <font-awesome-icon icon="download"></font-awesome-icon>
                            </button>
                        </div>
                    </div>
                </template>

            </b-table>
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="mt-4"/>
        </div>
        <b-modal size="xl" ref="cloneModal" hide-footer title="Enter new campaign name">
            <b-form-group>
                <b-form-input v-model="clone.name" placeholder="Enter new campaign name"></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-textarea v-model="clone.note" placeholder="Enter new campaign note"></b-textarea>
            </b-form-group>
            <b-form-group style="display: flex; justify-content: left;">
                <b-button variant="success" @click="doClone">Clone</b-button>
                <b-button style="margin-left: 5px" variant="outline-warning" @click="cancelClone">Cancel</b-button>
            </b-form-group>
        </b-modal>
    </main-layout>
</template>

<script>
import api from "../../api/Api";
import API from "../../api/Api";
import MainLayout from "../../layouts/MainLayout.vue";

export default {
    name: "ListCampaigns",
    components: {
        MainLayout
    },

    data: () => ({
        data: [],
        showFilters: false,
        form: false,
        model: {},
        global: {},
        clone: {},
        platforms: [],
        globalConfig: [],
        modelTemplate: {
            name: null,
            status: null,
            geo: []
        },
        filters: {
            name: null,
            status: 'active',
        },

        filterLabels: {
            name: 'Name',
            status: 'Status',
        },

        DDL: {
            status: [{value: 'active', text: 'Active'}, {value: 'archived', text: 'Archived'}],
            countries: [],
            browsers: [
                {id: 'google-chrome', name: "Google Chrome"},
                {id: 'firefox', name: "Firefox"},
                {id: 'safari', name: "Safari"},
                {id: 'opera', name: "Opera"},
                {id: 'internet-explorer', name: "Internet Explorer"},
                {id: 'edge', name: "Edge"}
            ],
            devices: [{id: 'desktop', name: "Desktop"}, {id: 'mobile', name: "Mobile"}, {id: 'tablet', name: "Tablet"}],
            languages: [{id: 'en', name: "English"}, {id: 'es', name: "Spanish"}, {id: 'de', name: "German"}],
            connections: [{id: 'wifi', name: "Wifi"}, {id: 'cellular', name: "Cellular"}, {id: 'ethernet', name: "Ethernet"}],
        },
        tableFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Campaign Name',
            },
            {
                key: 'sync_status',
                sortable: false,
                label: 'Sync Status',
            },
            // {
            //     key: 'geo',
            //     sortable: false,
            //     label: 'GEO',
            // },
            // {
            //     key: 'browsers',
            //     sortable: false,
            //     label: 'Browsers',
            // },
            // {
            //     key: 'devices',
            //     sortable: false,
            //     label: 'Devices',
            // },
            // {
            //     key: 'languages',
            //     sortable: false,
            //     label: 'Languages',
            // },
            // {
            //     key: 'connections',
            //     sortable: false,
            //     label: 'Connections',
            // },
            {
                key: 'status',
                sortable: true,
                label: 'Status'
            },
            {
                key: 'actions',
                sortable: false,
                label: 'Actions',
            }
        ],

        sortTable: {
            _id: -1
        },

        itemToShow: {},

        currentPage: 1,
        perPage: Number(window.localStorage.getItem('per-page')) || 10,
        totalRows: 0,
        perPageConfig: [
            {value: 10, text: "Per page: 10"},
            {value: 20, text: "Per page: 20"},
            {value: 30, text: "Per page: 30"},
            {value: 50, text: "Per page: 50"},
            {value: 100, text: "Per page: 100"},
        ],
    }),

    mounted: function () {
        this.loadDDL();
        this.loadData();
    },

    watch: {
        currentPage: function () {
            this.loadData();
        },

        perPage: function () {
            window.localStorage.setItem('per-page', this.perPage.toString());
            this.loadData();
        }
    },

    methods: {
        sort: function (ctx) {
            this.sortTable = {};
            this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
            this.currentPage = 1;

            this.loadData();
        },

        loadData: function () {
            api.post(`/campaign/list?page=${this.currentPage}&per-page=${this.perPage}`, {
                filter: this.filters,
                sort: this.sortTable,
            }).then(data => {
                this.totalRows = Number(data.headers['x-total-rows']) || 0;
                this.data = data.data.map(campaign => {
                    campaign.synced = 0;
                    campaign.outOfSync = 0;
                    if (campaign.configs) {
                        for (const config of Object.values(campaign.configs)) {
                            if (config.sync && config.sync.synced) {
                                campaign.synced++;
                            } else {
                                campaign.outOfSync++;
                            }
                        }
                    }
                    return campaign;
                })
            }).catch(e => this.error = e.message);
        },

        resetFilter: function () {
            for (const field in this.filters) {
                this.filters[field] = null;
                this.loadData();
            }
        },

        loadDDL() {
            API.get('/advertising/dropdown/countries?all=1').then(data => this.DDL.countries = data.data.map(item => {
                return {id: item.id, name: item.name};
            }));

            API.get('/advertising/platforms-by-feature/createCampaign').then(data => {
                const platforms = [];
                for (const platform of data.data) {
                    platforms.push({id: platform.id, name: platform.name});
                }

                this.platforms = platforms;
            });

            API.get('/campaign/global-config').then(data => {
                this.globalConfig = data.data;
            });
        },

        addCampaign() {
            this.form = true;
            this.model = Object.assign({}, this.modelTemplate);
        },

        save() {
            (
                this.model._id ? api.put(`/campaign/${this.model._id}`, this.model) : api.post('/campaign/create', this.model)
            ).then(response => {
                if (response.statusCode === 200) {
                    alert(`Unable to save campaign. Server error`);
                } else {
                    this.$notify({
                        title: 'Saved',
                        text: `Campaign template saved`,
                        group: 'app',
                        type: 'success'
                    });

                    //this.form = false;
                    //this.model = Object.assign({}, this.modelTemplate);
                    this.loadData();
                }
            }).catch(e => {
                alert(`Unable to save campaign. Server error: ${e.message}`);
            });
        },

        cancel() {
            this.form = false;
            this.model = Object.assign({}, this.modelTemplate);
            this.loadData();
        },

        edit(model) {
            this.form = true;
            const m = Object.assign({}, model);
            if (!m.platformGlobals) {
                m.platformGlobals = {};
            }

            for (const platform of this.platforms) {
                if (!m.platformGlobals[platform.id]) {
                    m.platformGlobals[platform.id] = {};
                }
            }

            for (const cnf of this.globalConfig) {
                const field = cnf.key;

                let globalDefault = this.globalConfig.find(cnf => cnf.key === field);
                if (globalDefault) {
                    globalDefault = globalDefault.default;
                }

                if (globalDefault) {
                    for (const platform in m.platformGlobals) {
                        if (!m.platformGlobals[platform][field]) {
                            m.platformGlobals[platform][field] = globalDefault[platform] || globalDefault['_'] || null;
                        }
                    }
                }
            }

            this.model = m;
        },

        remove(model) {
            if (confirm(`Are you sure you want to delete campaign ${model.name} and all related data?`) === false) {
                return;
            }

            api.delete(`/campaign/${model._id}`).then(() => this.loadData()).catch(e => alert(`Unable to delete campaign. Server error: ${e.message}`));
        },

        downloadAsJson(campaign) {
            // stringify campaign object and trigger download
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(campaign, null, 2));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", `${campaign.name}.json`);
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        },

        requestClone(campaign) {
            this.clone = {
                name: campaign.name + ' CLONE',
                note: campaign.note,
                campaign_id: campaign._id,
            }

            this.$refs.cloneModal.show();
        },

        doClone() {
            if (!this.clone || !this.clone.name) {
                alert('Please enter a name for the new campaign');
                return;
            }

            API.post('/campaign/clone', this.clone).then(() => {
                this.$refs.cloneModal.hide();
                this.$notify({
                    title: 'Success',
                    text: `Campaign cloned successfully`,
                    group: 'app',
                    type: 'success'
                });
                this.loadData();
            }).catch(e => {
                this.$notify({
                    title: 'Invalid',
                    text: `Unable to clone campaign with error ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            });
        },

        cancelClone() {
            this.clone = {};
            this.$refs.cloneModal.hide();
        }
    }
}
</script>
