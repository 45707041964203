<template>
    <login-layout header="Login">
        <b-form>
            <b-form-group id="username" label="Your username" label-for="username"
                          description="Enter username">
                <b-form-input id="usernameInput"
                              type="text"
                              :state="errors.username.state"
                              aria-describedby="usernameError" v-model="username" required
                              placeholder="Enter username"/>
                <b-form-invalid-feedback id="usernameError">{{errors.username.message}}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="password" label="Your password" label-for="password">
                <b-form-input id="passwordInput"
                              :state="errors.password.state"
                              aria-describedby="passwordError"
                              type="password"
                              v-model="password"
                              required placeholder="Enter password"/>
                <b-form-invalid-feedback id="passwordError">{{errors.password.message}}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <b-button type="button" @click="doLogin()" variant="primary">Login</b-button>
            </b-form-group>

            Dont have account?
            <router-link to="/register"><a>Click here</a></router-link>
        </b-form>
    </login-layout>
</template>

<script>
    import api from '../api/Api'
    import LoginLayout from '../layouts/LoginLayout'

    export default {
        components: {
            LoginLayout
        },
        data: () => ({
            errors: {
                username: {state: null, message: null},
                password: {state: null, message: null},
            },
            username: '',
            password: ''
        }),
        methods: {
            doLogin: async function () {
                this.errors.username = {state: true, message: null};
                this.errors.password = {state: true, message: null};

                try {
                    let response = await api.post('/users/login', {
                        username: this.username,
                        password: this.password
                    });

                    window.localStorage.setItem('auth_token', response.data.token);
                    window.location.href = '/links';
                } catch (e) {
                    this.errors.password.state = false;
                    this.errors.password.message = e.response.data.message;
                }
            }
        }
    }
</script>