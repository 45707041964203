<template>
    <main-layout>
        <div class="container">
            <h2 class="text-center">Links</h2>
            <div v-if="!form">
                <div class="row">
                    <button @click="form=true" class="btn btn-success">Add new link</button>
                    <b-table style="margin-top: 20px;" :items="links" :fields="tableFields" striped>
                        <template v-slot:cell(options)="row">
                            <button class="btn btn-danger btn-sm" @click="remove(row.item._id)">Remove</button>
                            &nbsp;
                            <button class="btn btn-info btn-sm" @click="edit(row.item)">Edit</button>

                            &nbsp;<router-link :to="'/link/'+row.item._id" class="btn btn-primary btn-sm">Show data
                        </router-link>
                            &nbsp;<a class="btn btn-info btn-sm" target="_blank" rel="nofollow" :href="row.item.link">Visit
                            MP</a>
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-else>
                <b-form>
                    <b-form-group id="name" label="Name" label-for="name" description="Name">
                        <b-form-input
                                :state="errors.name.state"
                                id="nameInput"
                                v-model="link.name"
                                type="text"
                                aria-describedby="nameError"
                                required
                        />
                        <b-form-invalid-feedback id="nameError">{{errors.name.message}}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="!link._id" id="url" label="URL" label-for="url" description="URL">
                        <b-form-input
                                :state="errors.url.state"
                                id="urlInput"
                                v-model="link.url"
                                type="text"
                                aria-describedby="urlError"
                                required
                        />
                        <b-form-invalid-feedback id="urlError">{{errors.url.message}}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="vendor" label="Vendor" label-for="vendor" description="Vendor">
                        <b-form-input
                                :state="errors.vendor.state"
                                id="vendorInput"
                                v-model="link.vendor"
                                type="text"
                                aria-describedby="vendorError"
                                required
                        />
                        <b-form-invalid-feedback id="vendorError">{{errors.vendor.message}}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="model" label="model" label-for="model" description="model">
                        <b-form-input
                                :state="errors.model.state"
                                id="modelInput"
                                v-model="link.model"
                                type="text"
                                aria-describedby="modelError"
                                required
                        />
                        <b-form-invalid-feedback id="modelError">{{errors.model.message}}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="!processing">
                        <b-button type="button" @click="saveLink" variant="primary">Save</b-button>
                        &nbsp;<button @click="cancelEdit" type="button" class="btn btn-warning">Cancel</button>
                    </b-form-group>
                    <p v-if="processing">
                        <b-img style="width: 100px" fluid :src="require('../images/processing.gif')"/>
                        Please, wait. Link is processing...
                    </p>
                </b-form>
            </div>
            <b-modal ref="removeModalRef" hide-footer title="Remove link">
                <div class="d-block text-center">
                    <h3>Remove this link and all relates items?</h3>
                </div>
                <b-button class="mt-3" variant="outline-danger" block @click="doRemove">Remove</b-button>
                <b-button class="mt-3" variant="outline-warning" block @click="hideModal">Cancel</b-button>
            </b-modal>
        </div>
    </main-layout>
</template>
<script>
    import API from "../api/Api";
    import MainLayout from "../layouts/MainLayout";

    export default {
        components: {
            MainLayout
        },
        data: () => ({
            form: false,
            links: [],
            processing: false,
            tableFields: [
                {
                    key: 'name',
                    sortable: false,
                    label: 'Name',

                },
                {
                    key: 'vendor',
                    sortable: false,
                    label: 'Vendor',
                },
                {
                    key: 'model',
                    sortable: false,
                    label: 'Model',
                },
                {
                    key: 'total_items',
                    sortable: false,
                    label: 'Total items',
                },
                {
                    key: 'options',
                    sortable: false,
                    label: 'Options'
                },
            ],

            link: {
                name: null,
                vendor: null,
                model: null,
                url: null,
            },

            errors: {
                name: {state: null, message: null},
                vendor: {state: null, message: null},
                model: {state: null, message: null},
                url: {state: null, message: null},
            },

            removeID: null,
        }),

        mounted: function () {
            this.loadLinks();
        },

        methods: {
            loadLinks: function () {
                API.get('/mp/links').then(data => {
                    this.links = data.data;
                }).catch(e => this.crypto = e.message);
            },

            remove: function (id) {
                this.removeID = id;
                this.showModal();
            },

            edit: function (link) {
                this.link = link;
                this.form = true;
            },

            showModal() {
                this.$refs.removeModalRef.show()
            },
            hideModal() {
                this.removeID = false;
                this.$refs.removeModalRef.hide()
            },

            doRemove: async function () {
                await API.delete(`/mp/links/${this.removeID}`);
                await this.loadLinks();
                this.hideModal();
            },

            flushErrors: function () {
                this.errors.name = {state: true, message: null};
                this.errors.vendor = {state: true, message: null};
                this.errors.model = {state: true, message: null};
                this.errors.url = {state: true, message: null};
            },

            saveLink: function () {
                this.processing = true;

                this.flushErrors();

                const request = this.link._id ? API.put(`/mp/links/${this.link._id}`, this.link) : API.post('/mp/links', this.link);

                request.then(() => {
                    this.loadLinks();
                    this.link = {};
                    this.processing = false;
                    this.form = false;
                }).catch(e => {
                    this.processing = false;
                    if (e.response.status === 422) {
                        e.response.data.forEach((error) => {
                            this.errors[error.param].message = error.msg;
                            this.errors[error.param].state = false;
                        });
                    } else {
                        alert('Something went wrong =(');
                    }
                });
            },

            cancelEdit: function () {
                this.flushErrors();
                this.form = false;
                this.link = {};
            }
        }
    }
</script>