<style>
    @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

    div.login-form {
        height: 100vh;
        background-color: aliceblue;
    }

    /* Sticky footer styles
 -------------------------------------------------- */
    html {
        position: relative;
        min-height: 100%;
    }
    body {
        margin-bottom: 50px; /* Margin bottom by footer height */
    }
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px; /* Set the fixed height of the footer here */
        line-height: 50px; /* Vertically center the text there */
        background-color: #f1edf5;
    }
</style>
<template>
    <div>
        <div class="login-form" style="padding-top: 10%;">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            {{header}}
                        </div>
                        <div class="card-body">
                            <slot/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer text-center">
            <div class="container">
                <span class="text-center"> CryptoScrape {{apiVersion}}</span>
            </div>
        </footer>
    </div>
</template>

<script>
    import api from '../api/Api';

    export default {
        props: ['header'],
        name: `LoginLayout`,

        data: () => ({
            apiVersion: null,
        }),

        mounted() {
            api.get('/software').then(res => this.apiVersion = res.data.version).catch(() => this.apiVersion = ' - ');
            this.locales = [
                {name: this.$t('english'), locale: 'en'}
            ];
        }

    };
</script>

