<style scoped="true">
table {
  font-size: 14px;
}
</style>
<template>
  <main>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="#">CryptoScrape</b-navbar-brand>

      <b-navbar-toggle target="nav_collapse"/>

      <b-collapse is-nav id="nav_collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/creatives">Creatives</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/campaign-templates">Campaign templates</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/campaigns">Campaigns</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/advertising-source-data">Advertising data</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/advertising-campaigns">Advertising campaigns</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/advertising">Advertising monitors</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/links">MP links</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/settings">{{ $t('settings') }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/logout">{{ $t('logout') }}</a>
            </li>
          </ul>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- Page Content -->
    <!--        <div class="container" style="margin-top: 20px;">-->
    <!--            <div class="row" style="display: flex; justify-content: flex-end">-->
    <!--                <span :style="{color: socket.connected ? 'green' : 'red'}">-->
    <!--                    Socket {{socket.connected ? 'connected' : 'not available'}}-->
    <!--                </span>-->
    <!--            </div>-->
    <!--        </div>-->
    <slot/>
  </main>
</template>

<script>
//import socket from '../api/socket.io'
export default {
  name: `MainLayout`,

  data: () => ({
    socket: {
      connected: false
    }
  })
};
</script>
