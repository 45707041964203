<template>
  <main-layout>
    <div class="container">
      <h2 class="text-center">Advertising charts</h2>
      <form class="filter-form">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Country</label>
            <b-select v-model="country_iso"
                      :options="[ {value: null, text: 'All'},].concat(countries)"/>
          </div>
          <div class="form-group col-md-4">
            <label>Carrier</label>
            <b-select :disabled="!!country_iso" v-model="carrier"
                      :options="[ {value: null, text: 'All'},].concat(carriers)"/>
          </div>
          <div class="form-group col-md-4">
            <label>Metric</label>
            <b-select v-model="field"
                      :options="fields"/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Date from</label>
            <b-input v-model="date_from" type="date"/>
          </div>
          <div class="form-group col-md-4">
            <label>Date to</label>
            <b-input v-model="date_to" type="date"/>
          </div>
          <div class="form-group col-md-4">
            <label>Data source</label>
            <b-select v-model="source_platform" :options="source_platforms"/>
          </div>
        </div>
      </form>
      <chart :options="chartOptions"></chart>
    </div>
  </main-layout>
</template>
<script>
import {Chart} from 'highcharts-vue'
import MainLayout from "../layouts/MainLayout";
import api from "../api/Api";
import moment from 'moment';
import API from "../api/Api";


export default {
  components: {
    MainLayout,
    Chart,
  },

  data: () => ({
    countries: [],
    carriers: [],
    fields: [],
    source_platforms: [],

    country_iso: null,
    carrier: null,
    field: 'ecpm_recent',
    date_from: null,
    date_to: null,
    source_platform: 'v1',

    rawData: [],
    isLoading: false,
    chartOptions: {
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
          rotation: 45,
          align: 'left'
        }
      },
      title: {
        text: 'Statistics chart'
      },
      chart: {
        zoomType: 'x'
      },
      plotOptions: {
        area: {
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      series: []
    },
  }),

  mounted: function () {
    this.initFilters();

    this.loadCountriesDropDown();
    this.loadCarriersDropDown();
    this.loadFieldsDropDown();
    this.loadSourcePlatformsDropdown();
    this.loadCharts();
  },

  watch: {
    country_iso: function () {
      this.carrier = null;
      this.loadCarriersDropDown();
      this.loadCharts();
    },

    carrier: function () {
      this.loadCharts();
    },

    field: function () {
      this.updateCharts();
    },
    date_from: function () {
      this.loadCharts();
    },
    date_to: function () {
      this.loadCharts();
    },
    source_platform: function () {
      this.loadCharts();
    }
  },

  methods: {
    loadCharts: function () {
      if (this.isLoading) {
        return; // @TODO find out right solution to remove this hack.
      }

      this.isLoading = true;

      api.post(`/advertising/chart`, {
        filter: {
          country_iso_code: this.country_iso,
          carrier: this.carrier,
          field: this.field,
          date_from: this.date_from,
          date_to: this.date_to,
          source_platform: this.source_platform,
        }
      }).then(data => {
        this.rawData = data.data;
        this.updateCharts();
      }).catch(e => this.crypto = e.message);

      setTimeout(() => this.isLoading = false, 500);
    },

    updateCharts: function () {
      this.chartOptions.series = this.rawData.map(series => {
        return {
          name: series._id,
          type: 'spline',
          data: series.series.map(s => {
            return [s['updated_at'], s[this.field]]
          })
        }
      });
    },

    initFilters() {
      this.country_iso = this.$route.query.country_iso || null;
      this.carrier = this.$route.query.carrier || null;
      this.date_from = moment().format('yyyy-MM-DD')
      this.date_to = moment().add(1, 'day').format('yyyy-MM-DD')
    },

    loadCarriersDropDown: function () {
      api.get(`/advertising/dropdown/carriers?country_iso_code=${this.country_iso || ''}`)
          .then(data => this.carriers = data.data.map(carrier => {
            return {value: carrier.id, text: carrier.name}
          }));
    },

    loadCountriesDropDown: function () {
      api.get('/advertising/dropdown/countries').then(data => this.countries = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
    },

    loadFieldsDropDown: function () {
      api.get('/advertising/dropdown/fields').then(data => this.fields = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
    },

    loadSourcePlatformsDropdown: function () {
      API.get('/advertising/dropdown/source-platforms').then(data => this.source_platforms = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
    }
  }
}
</script>