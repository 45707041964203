import Vue from 'vue'
import Router from 'vue-router'
import Links from './components/Links'
import Login from './components/Login'
import Register from './components/Register'
import RegistrationSuccess from "./components/RegistrationSuccess";
import Data from "./components/Data";
import Advertising from "./components/Advertising";
import AdvertisingSourceData from "./components/AdvertisingSourceData";
import AdvertisingChart from "./components/AdvertisingChart";
import Settings from "./components/Settings";
import AdvertisingCampaign from "@/components/AdvertisingCampaign";
import MultiPlatform from "./components/MultiPlatform";
import ListCampaigns from "@/components/Campaign/ListCampaigns.vue";
import CampaignPublish from "@/components/Campaign/CampaignPublish.vue";
import CampaignTemplateList from "@/components/CampaignTemplates/CampaignTemplateList.vue";
import CampaignTemplateForm from "@/components/CampaignTemplates/CampaignTemplateForm.vue";
import CampaignForm from "@/components/Campaign/CampaignForm.vue";
import CreativesList from "@/components/Creatives/CreativesList.vue";
import CreativeForm from "@/components/Creatives/CreativeForm.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/login', component: Login, name: 'Login'},
        {path: '/register', component: Register, name: 'Register'},
        {path: '/registration-success', component: RegistrationSuccess, name: 'Registration success'},

        {path: '/links', component: Links, name: 'Links'},
        {path: '/link/:id', component: Data, name: 'Data'},

        {path: '/advertising', component: Advertising, name: 'Advertising'},
        {path: '/advertising-campaigns', component: AdvertisingCampaign, name: 'AdvertisingCampaign'},
        {path: '/advertising-source-data', component: AdvertisingSourceData, name: 'Advertising source data'},
        {path: '/settings', component: Settings, name: 'Settings'},
        {path: '/advertising-chart', component: AdvertisingChart, name: 'Advertising charts'},
        {path: '/advertising-multi-platform', component: MultiPlatform, name: 'Advertising multi platform'},

        {path: '/campaign-templates', component: CampaignTemplateList, name: 'Campaign templates'},
        {path: '/campaign-templates/:id', component: CampaignTemplateForm, name: 'Edit campaign template'},

        {path: '/campaigns', component: ListCampaigns, name: 'Campaigns module'},
        {path: '/campaigns/:id', component: CampaignForm, name: 'Edit campaign'},
        {path: '/campaigns/publish/:id', component: CampaignPublish, name: 'Campaign publish'},
        {
            path: '/logout', redirect: () => {
                window.localStorage.removeItem('auth_token');
                return '/login';
            }
        },
        {path: '/creatives', component: CreativesList, name: 'Creatives list'},
        {path: '/creatives/:id', component: CreativeForm, name: 'Edit creative'},
    ]
});

router.beforeEach((to, from, next) => {
    if (window.localStorage.getItem('auth_token') !== null ||
        [
            '/login',
            '/register',
            '/registration-success',
        ].includes(to.path)) {
        next()
    } else {
        next('/login')
    }
});

export default router;
