<style scoped>
.btn {
    margin-left: 5px;
}
</style>

<template>
    <main-layout>
        <div class="container" style="width: 100%; margin-top: 20px">
            <div>
                <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
                    <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
                </div>
                <router-link to="/campaign-templates/new">
                    <button style="float: right; margin-top: 2px" class="btn btn-success btn-sm">Add new template</button>
                </router-link>
                <b-table
                        style="margin-top: 20px; width: 100%; font-size: 14px"
                        :items="data"
                        :fields="tableFields"
                        striped
                        no-local-sorting
                        @sort-changed="sort"
                >
                    <template v-slot:cell(actions)="row">
                        <router-link :to="'/campaign-templates/' + row.item._id">
                            <button class="btn btn-primary btn-sm">
                                <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                                Edit
                            </button>
                        </router-link>
                        <button class="btn btn-danger btn-sm" @click="remove(row.item)">
                            <font-awesome-icon icon="trash"></font-awesome-icon>
                            Delete
                        </button>
                        <button class="btn btn-info btn-sm" @click="requestClone(row.item)">
                            <font-awesome-icon icon="clone"></font-awesome-icon>
                            Clone
                        </button>
                        <button class="btn btn-primary btn-sm" @click="downloadAsJson(row.item)">
                            <font-awesome-icon icon="download"></font-awesome-icon>
                            Download
                        </button>
                    </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="mt-4"/>
            </div>
            <b-modal size="xl" ref="cloneModal" hide-footer title="Enter new campaign name">
                <b-form-group>
                    <b-form-input v-model="clone.name" placeholder="Enter new campaign name"></b-form-input>
                </b-form-group>
                <b-form-group style="display: flex; justify-content: left;">
                    <b-button variant="success" @click="doClone">Clone</b-button>
                    <b-button style="margin-left: 5px" variant="outline-warning" @click="cancelClone">Cancel</b-button>
                </b-form-group>
            </b-modal>
        </div>
    </main-layout>
</template>

<script>

import API from "../../api/Api";
import MainLayout from "../../layouts/MainLayout";

export default {
    name: "CampaignTemplateList",
    components: {
        MainLayout
    },

    data: () => ({
        data: [],
        clone: {},
        tableFields: [
            {
                key: 'name',
                sortable: true,
                label: 'Template name',
            },
            {
                key: 'platform',
                sortable: true,
                label: 'Platform',
            },
            {
                key: 'actions',
                sortable: false,
                label: 'Actions',
            }
        ],

        sortTable: {_id: -1},

        // Pagination
        currentPage: 1,
        perPage: Number(window.localStorage.getItem('per-page')) || 10,
        totalRows: 0,
        perPageConfig: [
            {value: 10, text: "Per page: 10"},
            {value: 20, text: "Per page: 20"},
            {value: 30, text: "Per page: 30"},
            {value: 50, text: "Per page: 50"},
            {value: 100, text: "Per page: 100"},
        ],
    }),

    mounted: function () {
        this.loadData();
    },

    watch: {
        currentPage: function () {
            this.loadData();
        },

        perPage: function () {
            window.localStorage.setItem('per-page', this.perPage.toString());
            this.loadData();
        }
    },

    methods: {
        sort: function (ctx) {
            this.sortTable = {};
            this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
            this.currentPage = 1;

            this.loadData();
        },

        loadData: function () {
            API.post(`/campaign-template/list?page=${this.currentPage}&per-page=${this.perPage}`, {
                //filter: this.filters,
                sort: this.sortTable,
            }).then(data => {
                this.totalRows = Number(data.headers['x-total-rows']) || 0;
                this.data = data.data;
            }).catch(e => this.error = e.message);
        },

        remove(model) {
            if (confirm(`Are you sure you want to delete template ${model.name} and all related data?`) === false) {
                return;
            }

            API.delete(`/campaign-template/${model._id}`).then(() => this.loadData()).catch(e => alert(`Unable to delete template. Server error: ${e.message}`));
        },

        downloadAsJson(template) {
            // stringify template object and trigger download
            const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(template, null, 2));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", `${template.name}.json`);
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        },

        requestClone(template) {
            this.clone = {
                name: template.name + ' CLONE',
                template_id: template._id,
            }

            this.$refs.cloneModal.show();
        },

        doClone() {
            if (!this.clone || !this.clone.name) {
                alert('Please enter a name for the new template');
                return;
            }

            API.post('/campaign-template/clone', this.clone).then(() => {
                this.$refs.cloneModal.hide();
                this.$notify({
                    title: 'Success',
                    text: `Template cloned successfully`,
                    group: 'app',
                    type: 'success'
                });
                this.loadData();
            }).catch(e => {
                this.$notify({
                    title: 'Invalid',
                    text: `Unable to clone template with error ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            });
        },

        cancelClone() {
            this.clone = {};
            this.$refs.cloneModal.hide();
        }
    }
}
</script>
