import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: window.localStorage.getItem('locale') || 'en',
    fallbackLocale: 'en',
    messages: {
        'en': {
            english: 'English',
            enter_username: 'Enter username',
            your_email: 'Your email',
            enter_email: 'Enter email',
            your_full_name: 'Your full name',
            enter_full_name: 'Enter full name',
            login: 'Login',
            email_success_confirmed: 'Email success confirmed',
            invalid_token: 'Invalid token',
            edit: 'Edit',
            remove: 'Remove',
            enter_name: 'Enter name',
            back_to_list: 'Back to list',
            cancel: 'Cancel',
            created_at: 'Created date',
            options: 'Options',
            update: 'Update',
            enable: 'Enable',
            disable: 'Disable',
            no_action: 'No action',
            date: 'Date',
            all: 'All',
            active: 'Active',
            blocked: 'Blocked',
            filter: 'Filter',
            full_name: 'Full name',
            admin: 'Admin',
            type: 'Type',
            global: 'Global',
            your_username: 'Your username',
            your_password: 'Your password',
            enter_password: 'Enter password',
            dont_have_account: 'Don\'t have account yet',
            click_here: 'Click here',
            forgot_password: 'Forgot Password',
            login_here: 'Login here',
            already_have_account: 'Already have account',
            register_now: 'Register now',
            registration: 'Registration',
            name: 'Name',
            success_password_reset: 'Success. More instructions was sent to your email',
            back_to_login: 'Back to login',
            reset_password: 'Reset password',
            password_success_updated: 'Password success updated',
            repeat_password: 'Repeat password',
            set_new_password: 'Set new password',
            passwords_not_match: 'Passwords doesn\'t match',
            success_registration: 'Success registration. Please check your email',
            logout: 'Logout',
            per_page: 'Per page',
            show_filters: 'Show filters',
            hide_filters: 'Hide filters',
            settings: 'Settings'
        },
    }
});