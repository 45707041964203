<style scoped>
/**/
</style>

<template>
    <main-layout>
        <div class="container" style="width: 100%; margin-top: 20px; margin-bottom: 30px">
            <div v-if="model">
                <b-form-checkbox
                        v-if="form"
                        v-model="form.show_optional"
                        switch
                        :unchecked-value="false"
                        :checked-value="true"
                >
                    Show optional
                </b-form-checkbox>
                <hr/>

                <div class="form-group">
                    <label>Template name</label>
                    <input type="text" class="form-control" v-model="model.name" placeholder="Enter template name">
                </div>
                <div class="form-group">
                    <label>Platform</label>
                    <v-select
                            :options="platforms"
                            :reduce="option => option.id"
                            placeholder="Select platform"
                            label="name"
                            :disabled="model && !!model.platform && !!model._id"
                            v-model="model.platform"
                    ></v-select>
                </div>
                <div v-if="form">
                    <div v-if="form.loading">
                        <span style="text-align: center;">
                            <b-img style="width: 200px; display: block; margin-left: auto;margin-right: auto;" fluid :src="require('../../images/processing.gif')"/>
                        </span>
                    </div>
                    <div>
                        <FormRender
                            v-on:revalidate="revalidate"
                            :initial-form="form"
                            :form="form"
                            :model="form.model"
                            :errors="form.errors"
                            :template-mode="true"
                        ></FormRender>

                        <div style="display: flex; flex-direction: row; justify-content: left; gap: 1%">
                            <!--                            <div>-->
                            <!--                                <b-button variant="info" @click="validate(form.platform)">Validate</b-button>-->
                            <!--                            </div>-->
                            <div>
                                <b-button variant="success" @click="save(form.platform)">
                                    <font-awesome-icon icon="save"></font-awesome-icon>
                                    Save
                                </b-button>
                            </div>
                            <div>
                                <b-button variant="warning" @click="exit">Exit</b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div>
                        <b-button variant="warning" @click="exit">Exit</b-button>
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import API from "../../api/Api";
import FormRender from "@/components/FormRender.vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
    name: "CampaignTemplateForm",
    components: {
        MainLayout,
        FormRender
    },

    // Watch for model.platform change
    watch: {
        // Watch deep for model.platform
        'model.platform': {
            handler: function () {
                this.buildForm(false);
            },
            deep: true
        },
    },

    data() {
        return {
            platforms: [],
            model: null,
            errors: [],
            form: null,
        };
    },

    destroyed() {
        document.removeEventListener('keydown', this.saveEventHandler);
    },

    mounted() {
        // Capture ctrl+s
        document.addEventListener('keydown', this.saveEventHandler);

        if (this.$route.params.id === 'new') {
            this.model = {};
        } else {
            API.get(`/campaign-template/${this.$route.params.id}`).then(response => {
                if (response.status !== 200) {
                    // Show error alert
                    this.$notify({
                        title: 'Failed',
                        text: `Unable to load template. Status code: ${response.status}`,
                        group: 'app',
                        type: 'error'
                    });
                }

                this.model = response.data;
                if (this.model) {
                    this.buildForm();
                }
            }).catch(e => {
                // Show error alert
                this.$notify({
                    title: 'Failed',
                    text: `Unable to load template. Status code: ${e.response.status}`,
                    group: 'app',
                    type: 'error'
                });
            })
        }

        API.get('/advertising/platforms-by-feature/createCampaign').then(data => {
            this.platforms = data.data;
        });
    },

    methods: {
        async buildForm(revalidate = false) {
            if (!this.model.platform) {
                return;
            }

            if (this.form && this.form.platform !== this.model.platform) {
                this.form = null;
            }

            if (this.form && !revalidate) {
                return;
            }

            if (!this.form) {
                this.form = {
                    loading: false,
                    model: this.model ? this.model.model || {} : {},
                };
            }

            this.form.loading = !revalidate;

            let model = this.form.model;

            API.post(`campaign-template/render-config/${this.model.platform}`, {
                model: model,
            }).then(response => {
                this.form = {
                    ...response.data,
                    platform: this.model.platform,
                    loading: false,
                    show_optional: false,
                    show_prefilled: true,
                };
            }).catch(e => {
                this.form = {
                    platform: this.model.platform,
                    error: e.message,
                    loading: false,
                };
            });
        },

        validate() {
            this.form.errors = {};

            API.post('/campaign-template/validate', {
                platform: this.model.platform,
                model: this.form.model
            }).then(result => {
                if (result.data.success) {
                    this.$notify({
                        title: 'Success',
                        text: `Template is valid!`,
                        group: 'app',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Invalid',
                        text: `Template form is invalid!`,
                        group: 'app',
                        type: 'error'
                    });

                    this.processErrors(result);
                }
            }).catch(e => {
                this.$notify({
                    title: 'Error',
                    text: `Unable to validate template form. ERR: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            });
        },

        save() {
            if (!this.form) {
                this.$notify({
                    title: 'Nothing to save',
                    text: `Select platform first`,
                    group: 'app',
                    type: 'warn'
                });
                return;
            }
            this.form.errors = {};

            const method = this.model._id ? API.put : API.post;

            method(`/campaign-template${this.model._id ? '/' + this.model._id : ''}`, {
                name: this.model.name,
                platform: this.model.platform,
                model: this.form.model,
            }).then(result => {
                if (result.data.success) {
                    this.$notify({
                        title: 'Template saved',
                        text: `Template ${this.model.name} was saved successfully`,
                        group: 'app',
                        type: 'success'
                    });
                    if (result.data._id) {
                        this.model._id = result.data._id;
                    }
                } else {
                    this.$notify({
                        title: 'Platform config save failed',
                        text: `Template ${this.model.name} was not saved`,
                        group: 'app',
                        type: 'error'
                    });
                    this.processErrors(result);
                }
            }).catch(e => {
                this.$notify({
                    title: 'Platform config save failed',
                    text: `Template ${this.model.name} was not saved. Error: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            })
        },

        processErrors(result) {
            let errors = {};
            for (const error of result.data.errors) {
                if (error.key.includes('.')) {
                    let errorObj = errors;
                    const path = error.key.split('.');

                    for (const i in path) {
                        const key = path[i];
                        if (Number(i) === path.length - 1) {
                            errorObj[key] = {state: false, message: error.message};
                        } else {
                            if (!errorObj[key]) {
                                errorObj[key] = {};
                            }

                            errorObj = errorObj[key];
                        }
                    }
                } else {
                    errors[error.key] = {state: false, message: error.message};
                }
            }

            this.form.errors = errors
        },

        revalidate() {
            this.buildForm(true);
        },

        exit() {
            if (!this.form || confirm('Are you sure you want to exit? All unsaved changes will be lost.')) {
                this.$router.push({path: '/campaign-templates'});
            }
        },

        saveEventHandler(e) {
            if (e.key === 's' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                this.save();
            }
        }
    }
}
</script>
