<style>
    @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

    body {
        margin: 0;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.6;
        color: #212529;
        text-align: left;
        background-color: #f5f8fa;
    }

    .navbar-laravel {
        box-shadow: 0 2px 4px rgba(0, 0, 0, .04);
    }

    .navbar-brand, .nav-link, .my-form, .login-form {
        font-family: Raleway, sans-serif;
    }

    .my-form {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .my-form .row {
        margin-left: 0;
        margin-right: 0;
    }

    .login-form {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .login-form .row {
        margin-left: 0;
        margin-right: 0;
    }
</style>
<template>
    <login-layout :header="$t('registration')">
        <b-form>
            <b-form-group id="username" :label="$t('your_username')" label-for="username"
                          :description="$t('enter_username')">
                <b-form-input id="usernameInput" type="text" :state="errors.username.state"
                              aria-describedby="usernameError" v-model="username" required
                              :placeholder="$t('enter_username')"></b-form-input>
                <b-form-invalid-feedback id="usernameError">{{errors.username.message}}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="email" label="Your email" label-for="email"
                          :description="$t('enter_email')">
                <b-form-input id="emailInput" type="text" :state="errors.email.state"
                              aria-describedby="emailError" v-model="email" required
                              :placeholder="$t('enter_email')"></b-form-input>
                <b-form-invalid-feedback id="emailError">{{errors.email.message}}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="full_name" :label="$t('your_full_name')" label-for="full_name"
                          :description="$t('enter_full_name')">
                <b-form-input id="full_nameInput" type="text" :state="errors.full_name.state"
                              aria-describedby="full_nameError" v-model="full_name" required
                              :placeholder="$t('enter_full_name')"></b-form-input>
                <b-form-invalid-feedback id="full_nameError">{{errors.full_name.message}}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="password" :label="$t('your_password')" label-for="password">
                <b-form-input id="passwordInput" :state="errors.password.state"
                              aria-describedby="passwordError" type="password" v-model="password"
                              required :placeholder="$t('enter_password')"></b-form-input>
                <b-form-invalid-feedback id="passwordError">{{errors.password.message}}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <b-button type="button" @click="doRegistration()" variant="primary">{{$t('register_now')}}
                </b-button>
            </b-form-group>
            <p>{{$t('already_have_account')}}?
                <router-link to="/login">{{$t('login_here')}}</router-link>
            </p>
        </b-form>
    </login-layout>
</template>

<script>
    import api from '../api/Api'
    import LoginLayout from '../layouts/LoginLayout'

    export default {
        components: {
            LoginLayout
        },
        data: () => ({
            errors: {
                username: {state: null, message: null},
                password: {state: null, message: null},
                email: {state: null, message: null},
                full_name: {state: null, message: null},
            },
            username: '',
            password: '',
            email: '',
            full_name: '',
        }),
        methods: {
            doRegistration: async function () {
                this.errors.username = {state: true, message: null};
                this.errors.password = {state: true, message: null};
                this.errors.email = {state: true, message: null};
                this.errors.full_name = {state: true, message: null};

                try {
                    await api.post('/users/register', {
                        username: this.username,
                        password: this.password,
                        email: this.email,
                        full_name: this.full_name,
                    });

                    //sessionStorage.setItem('auth_token', response.data.token);
                    window.location.href = '/registration-success';
                } catch (e) {
                    e.response.data.forEach((error) => {
                        this.errors[error.param].message = error.msg;
                        this.errors[error.param].state = false;
                    });
                }
            }
        }
    }
</script>