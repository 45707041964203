<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <main-layout>
    <div class="container-fluid" style="padding-right: 120px; padding-left: 120px;">
      <h2 class="text-center">Advertising multi campaign runner</h2>
      <b-form-group>
        <b-form-checkbox-group
            style="user-select: none"
            id="checkbox-group-1"
            v-model="selectedPlatforms"
            :options="platforms"
        ></b-form-checkbox-group>
      </b-form-group>

      <b-form-group v-for="input in form" v-bind:key="input.key">
        <label>{{ input.label }} <span style="color: red" v-if="input.required">*</span></label>
        <b-form-input
            :state="errors[input.key] ? errors[input.key].state : null"
            type="text" v-if="input.type === 'text'"
            :maxlength="input.max || null"
            :minlength="input.min || null" class="form-control"
            v-model="model[input.key]"
        />
        <b-form-input
            type="number"
            v-if="input.type === 'number'"
            :step="input.step || 0.1"
            :min="input.min || null"
            :max="input.max || null"
            class="form-control"
            v-model="model[input.key]"
            :state="errors[input.key] ? errors[input.key].state : null"
        />
        <b-form-input
            type="date"
            v-if="input.type === 'date'"
            :min="input.min || null"
            :max="input.max || null"
            class="form-control"
            v-model="model[input.key]"
            :state="errors[input.key] ? errors[input.key].state : null"
        />
        <v-select
            v-if="input.type === 'select'"
            :options="input.options"
            :reduce="option => option.id"
            :multiple="!!input.multiple"
            label="label"
            v-model="model[input.key]"
            :taggable="!!input.custom_values"
            :create-option="option => {return {id: option, label: option}}"
        ></v-select>

        <span style="color: red" v-if="errors[input.key]">{{ errors[input.key].message }}</span>
      </b-form-group>

      <b-button variant="primary" @click="validateModel">Validate</b-button>
    </div>
  </main-layout>
</template>
<script>
import 'vue-select/dist/vue-select.css';
import API from "../api/Api";
import MainLayout from "../layouts/MainLayout";

export default {
  components: {
    MainLayout
  },
  data: () => ({
    platforms: [],
    selectedPlatforms: [],
    platformConfigs: {},
    form: [],
    model: {},
    errors: {}
  }),

  mounted: async function () {
    const data = await API.get('/advertising/platforms-by-feature/createCampaign');
    for (const platform of data.data) {
      this.platforms.push({value: platform.id, text: platform.name});
      this.platformConfigs[platform.id] = null;
    }
  },

  watch: {
    selectedPlatforms: function () {
      this.loadConfig();
    }
  },

  methods: {
    async loadConfig() {
      const configs = Object.assign({}, this.platformConfigs); // WTF VUEJS??
      for (const platform of this.selectedPlatforms) {
        if (!configs[platform]) {
          const data = await API.get(`/advertising/platform-config/${platform}`);
          configs[platform] = data.data.createCampaign.input;
        }
      }

      this.platformConfigs = configs;
      this.buildForm();
    },

    buildForm() {
      // @TODO All kind of sorts here.
      let inputs = [];
      for (const platform of this.selectedPlatforms) {
        const platformInputs = this.platformConfigs[platform] || {};
        for (const key in platformInputs) {
          inputs.push({
            ...platformInputs[key],
            key: key,
          });
        }
      }

      const model = Object.assign({}, this.model);
      const errors = {}

      for (let input of inputs) {
        if (!this.model[input.key] && input.default !== null && input.default !== undefined) {
          model[input.key] = input.default;
          errors[input.key] = {state: null, message: null};
        }
      }

      this.errors = errors;
      this.model = model;

      inputs = inputs
          .sort(input => input.required ? -1 : 0) // Required fields first
          .sort(input => input.type === 'text' ? -1 : 0) // Required fields first

      this.form = inputs;
    },

    validateModel() {
      API.post('/advertising/validate-multi-campaign', {
        platforms: this.selectedPlatforms,
        model: this.model,
      }).then(result => {
        if (result.data.success) {
          alert('Form is valid!');
        } else {
          let errors = {};
          for (const error of result.data.errors) {
            errors[error.key] = {state: false, message: error.message};
          }

          this.errors = errors;
        }
      }).catch(e => alert(`Validation request failed. ERR: ${e.message}`));
    },
  }
}
</script>

