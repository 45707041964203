<style scoped></style>

<template>
    <div>
        <b-tabs content-class="mt-3">
            <b-tab v-for="form in forms" v-bind:key="form.template.id" :title="form.template.name">
                <template #title>
                    {{ form.template.name }}
                    <span style="padding-left: 10px;" v-if="!form.sync.syncing">
                    <font-awesome-icon v-if="form.sync.synced" style="color: green" icon="check"></font-awesome-icon>
                    <font-awesome-icon v-else style="color: #fd7e14" icon="exclamation-triangle"></font-awesome-icon>
                </span>
                    <span v-else>
                    <b-spinner type="border" small></b-spinner>
                </span>
                </template>

                <div v-if="form.loading">
                <span style="text-align: center;">
                    <b-img style="width: 200px; display: block; margin-left: auto;margin-right: auto;" fluid
                           :src="require('../../images/processing.gif')"/>
                </span>
                </div>
                <div v-else>
                    <span :style="{color: form.sync.synced ? 'green' : 'red'}">
                        <b>{{ form.sync.synced ? 'Synced' : 'Out of sync' }}</b>.
                        Last sync: {{ form.sync.synced_at ? form.sync.synced_at : 'Never synced' }}
                    </span>
                    <p>
                        <router-link v-if="form.template._id" :to="'/campaign-templates/' + form.template._id">Template
                            config
                        </router-link>
                    </p>

                    <div>
                        <FormRender
                                :initial-form="form.form"
                                :form="form.form"
                                :model="form.model"
                                :errors="form.errors"
                                :template-mode="false"
                                v-on:revalidate="revalidate"
                        ></FormRender>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: left; gap: 2%">
                        <div style="display: flex; justify-content: left; gap: 3px;">
                            <b-button
                                    variant="success"
                                    @click="sync(form)"
                                    :disabled="form.sync.syncing"
                                    v-if="!form.sync.synced"
                            >
                                <b-spinner v-if="form.sync.syncing" type="border" small></b-spinner>
                                <font-awesome-icon v-else icon="upload"></font-awesome-icon>
                                Sync
                            </b-button>
                            <b-button v-if="campaign && campaign._id" style="margin-left: 5px" @click=showSyncLogs(form)
                                      size="sm" variant="info">Sync logs
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
        <b-modal size="xl" ref="syncLogModal" hide-footer title="Sync logs">
            <pre v-for="log in syncLogs" v-bind:key="log._id">{{ log }}<hr/></pre>
        </b-modal>
    </div>
</template>

<script>

import API from "../../api/Api";
import FormRender from "@/components/FormRender.vue";

export default {
    name: "PlatformConfigs",
    components: {FormRender},
    emits: ['sync', 'showSyncLogs'],
    props: {
        globalName: String,
        templates: Array,
        models: Object,
        campaign: Object,
    },

    watch: {
        'templates': {
            handler: function () {
                this.handleTemplatesChange();
            },
            deep: true,
        },
        'globalName': {
            handler: function () {
                this.updateNames();
            },
            deep: true,
        },
    },

    mounted() {
        this.handleTemplatesChange();
    },

    data() {
        return {
            templateCollection: [],
            platformCollection: [],
            forms: [],
            syncLogs: [],
        };
    },

    methods: {
        handleTemplatesChange: async function () {
            for (const _id of this.templates) {
                const alreadyLoaded = this.templateCollection.find(t => t._id === _id);

                if (!alreadyLoaded) {
                    const response = await API.get(`/campaign-template/${_id}`);
                    this.templateCollection.push(response.data);
                }
            }

            await this.buildForms();
        },

        async buildForms() {
            const forms = [];

            for (const templateId of this.templates) {
                const template = this.templateCollection.find(t => t._id === templateId);
                if (!template) {
                    continue;
                }

                const form = this.forms.find(f => f.template._id === template._id);
                const savedModel = this.models[template._id] ? this.models[template._id].model : {};

                if (form && form.model) {
                    for(const key in form.model) {
                        if (!savedModel[key]) {
                            savedModel[key] = form.model[key];
                        }
                    }
                }

                const platformConfig = await API.post(
                    `campaign-template/render-config/${template.platform}`,
                    {
                        model: form ? this.getModel(form) : savedModel,
                    }
                );

                const formatted = this.formatExcluded(
                    platformConfig.data,
                    template.model,
                    savedModel
                );

                let syncStatus = {
                    syncing: false,
                    synced: false,
                };

                if (this.models[template._id] && this.models[template._id].sync) {
                    syncStatus = this.models[template._id].sync;
                }

                forms.push({
                    ...platformConfig.data,
                    template: template,
                    originalModel: template.model,
                    model: formatted.model,
                    form: formatted.form,
                    sync: syncStatus,
                });
            }

            this.forms = forms;
            this.updateNames();
        },

        formatExcluded(form, model, savedModel) {
            const flatModel = this.makeFlatModel(model);
            const savedFlatModel = this.makeFlatModel(savedModel);

            const newForm = {
                input: {}
            };

            for (const key in flatModel) {
                const keyParts = key.split('.');

                let tmpForm = Object.assign({}, form);

                for (const part of keyParts) {
                    if (tmpForm.input && tmpForm.input[part]) {
                        tmpForm = tmpForm.input[part];
                    } else if (!isNaN(Number(part)) && tmpForm.inputs && Array.isArray(tmpForm.inputs) && tmpForm.inputs.length > 0) {
                        newForm.input[key] = tmpForm.inputs[0];

                        flatModel[key] = [];
                        tmpForm = null; // Prevents the loop from continuing
                        break;
                    } else {
                        tmpForm = null;
                        break;
                    }
                }

                if (tmpForm) {
                    if (flatModel[key] === '__excluded__') {
                        newForm.input[key] = tmpForm;
                        flatModel[key] = null;
                    } else if (tmpForm.inputs) {
                        if (Array.isArray(flatModel[key]) && flatModel[key].length > 0) {
                            newForm.input[key] = tmpForm;
                            flatModel[key] = Array.isArray(flatModel[key])
                                ? flatModel[key].map(item => {
                                    for (const k in item) {
                                        if (item[k] === '__excluded__') {
                                            item[k] = null;
                                        }
                                    }

                                    return item;
                                }) : [];
                        }
                    }
                }
            }

            newForm.show_optional = true;

            return {
                form: newForm,
                model: {
                    ...flatModel,
                    ...savedFlatModel,
                },
            };
        },

        makeFlatModel(model) {
            const flatModel = {};

            for (const key in model) {
                if (typeof model[key] === 'object' && !Array.isArray(model[key])) {
                    const flatSubModel = this.makeFlatModel(model[key]);

                    for (const subKey in flatSubModel) {
                        flatModel[`${key}.${subKey}`] = flatSubModel[subKey];
                    }
                } else {
                    flatModel[key] = model[key];
                }
            }

            return flatModel;
        },

        updateNames() {
            for (const form of this.forms || []) {
                switch (form.template.platform) {
                    case 'advertiser':
                    case 'propeller_ads':
                        form.model.name = form.model.name || this.globalName || null;
                        break;
                    case 'rollerads':
                        form.model.campaign_title = form.model.campaign_title || this.globalName || null;
                }
            }
        },

        getModel(form) {
            return this.merge(form.originalModel, form.model);
        },

        sync(form) {
            this.$emit('sync', form);
        },

        showSyncLogs(form) {
            if (!this.campaign || !this.campaign._id) {
                return;
            }
            API.get(`/campaign/sync-logs/${this.campaign._id}/${form.template._id}`).then(response => {
                this.syncLogs = response.data || []
                this.$refs.syncLogModal.show();
            }).catch(e => {
                this.$notify({
                    title: 'Unable to load sync logs',
                    text: `Request failed with error: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            });
        },

        merge(original, updates) {
            const copy = {};
            for (const key in original) {
                if (typeof original[key] === 'object' && !Array.isArray(original[key]) && original[key] !== null) {
                    const updatesCopy = {};
                    for (const k in updates) {
                        if (k.indexOf(key) === 0) {
                            updatesCopy[k.split('.').slice(1).join('.')] = updates[k];
                        }
                    }

                    copy[key] = this.merge(original[key], updatesCopy);
                } else {
                    copy[key] = updates[key] !== undefined ? updates[key] : original[key];
                }
            }

            return copy;
        },

        getCampaigns() {
            const campaigns = {};
            for (const form of this.forms) {
                campaigns[form.template._id] = {
                    platform: form.template.platform,
                    model: this.getModel(form),
                    template: form.template,
                };
            }

            return campaigns;
        },

        revalidate() {
            this.buildForms(true);
        }
    }
}
</script>
