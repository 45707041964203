<style scoped>
/**/
</style>

<template>
    <main-layout>
        <div class="container-fluid" style="width: 100%; margin-top: 20px; margin-bottom: 30px">
            <div v-if="model" style="display: flex; justify-content: space-between">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Campaign name</label>
                        <input type="text" class="form-control" v-model="model.name" placeholder="Enter campaign name">
                    </div>
                    <div class="form-group">
                        <label>Campaign note</label>
                        <textarea class="form-control" v-model="model.note" placeholder="Enter campaign note"></textarea>
                    </div>
                    <div class="form-group">
                        <label>Campaign status</label>
                        <v-select
                                :options="[{id: 'active', name: 'Active'}, {id: 'archived', name: 'Archived'}]"
                                :reduce="option => option.id"
                                placeholder="Select campaign status"
                                label="name"
                                :multiple="false"
                                v-model="model.status"
                        ></v-select>

                    </div>
                    <div class="form-group">
                        <label>Templates</label>
                        <v-select
                                :options="templates"
                                :reduce="option => option._id"
                                placeholder="Select templates"
                                label="name"
                                :multiple="true"
                                v-model="model.templates"
                        ></v-select>
                    </div>
                    <div style="display: flex; justify-content: left; gap: 5px;">
                        <b-button variant="warning" @click="exit">Exit</b-button>
                        <b-button variant="success" @click="save">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                            Save
                        </b-button>
                    </div>
                </div>
                <div class="col-md-8">
                    <PlatformConfigs
                            ref="platformConfigs"
                            :templates="model.templates"
                            :global-name="model.name"
                            :models="model.configs"
                            :campaign="model"
                            v-on:sync="sync"
                    ></PlatformConfigs>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import API from "../../api/Api";
import MainLayout from "@/layouts/MainLayout.vue";
import PlatformConfigs from "@/components/Campaign/PlatformConfigs.vue";

export default {
    name: "CampaignForm",
    components: {
        PlatformConfigs,
        MainLayout
    },

    data() {
        return {
            templates: [],
            model: null,
        };
    },

    destroyed() {
        document.removeEventListener('keydown', this.saveEventHandler);
    },

    mounted() {
        // Capture ctrl+s
        document.addEventListener('keydown', this.saveEventHandler);

        if (this.$route.params.id === 'new') {
            this.model = {
                templates: [],
                configs: {},
                status: 'active',
            };
        } else {
            API.get(`/campaign/${this.$route.params.id}`).then(response => {
                if (response.status !== 200) {
                    // Show error alert
                    this.$notify({
                        title: 'Failed',
                        text: `Unable to load campaign. Status code: ${response.status}`,
                        group: 'app',
                        type: 'error'
                    });
                }

                this.model = response.data;
            }).catch(e => {
                // Show error alert
                this.$notify({
                    title: 'Failed',
                    text: `Unable to load campaign. Status code: ${e.response.status}`,
                    group: 'app',
                    type: 'error'
                });
            })
        }

        API.post(`/campaign-template/list?per-page=1000`, {
            sort: {created_at: -1},
        }).then(data => {
            this.templates = data.data;
        }).catch(e => this.error = e.message);
    },

    methods: {
        save() {
            const method = this.model._id ? API.put : API.post;

            const endpoint = this.model._id ? `/campaign/${this.model._id}` : `/campaign/create`;

            method(endpoint, {
                name: this.model.name,
                note: this.model.note,
                templates: this.model.templates,
                configs: this.$refs.platformConfigs.getCampaigns(),
                status: this.model.status,
            }).then(result => {
                if (result.status === 200) {
                    this.$notify({
                        title: 'Campaign saved',
                        text: `Campaign ${this.model.name} was saved successfully`,
                        group: 'app',
                        type: 'success'
                    });

                    if (result.data.insertedId) {
                        this.model._id = result.data.insertedId;
                    }
                } else {
                    this.$notify({
                        title: 'Campaign save failed',
                        text: `Campaign ${this.model.name} was not saved`,
                        group: 'app',
                        type: 'error'
                    });
                }
            }).catch(e => {
                this.$notify({
                    title: 'Campaign save failed',
                    text: `Campaign ${this.model.name} was not saved. Error: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            })
        },

        exit() {
            if (!this.form || confirm('Are you sure you want to exit? All unsaved changes will be lost.')) {
                this.$router.push({path: '/campaigns'});
            }
        },

        saveEventHandler(e) {
            if (e.key === 's' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                this.save();
            }
        },

        async sync(form) {
            const campaigns = this.$refs.platformConfigs.getCampaigns();
            if (!form.template._id || !campaigns[form.template._id]) {
                this.$notify({
                    title: 'Unable to sync',
                    text: `You must save this campaign before you can sync it`,
                    group: 'app',
                    type: 'error'
                });
                return;
            }

            const campaign = campaigns[form.template._id];

            form.errors = {};
            form.sync.syncing = true;

            try {
                const validationResult = await API.post('/campaign/validate-publish-config', {
                    platform: form.template.platform,
                    model: campaign.model
                });

                if (!validationResult.data.success) {
                    this.$notify({
                        title: 'Invalid',
                        text: `${form.template.name} form is invalid!`,
                        group: 'app',
                        type: 'error'
                    });

                    form.errors = this.formatErrors(validationResult);
                    form.sync.syncing = false;
                    return;
                }
            } catch (e) {
                this.$notify({
                    title: 'Error',
                    text: `Unable to validate ${form.template.name} form. Error: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            }

            // @todo pull remote campaign and compare state in order to check if there's any unsaved changes.

            API.post('/campaign/sync', {
                campaign_id: this.model._id,
                template_id: form.template._id,
                model: campaign.model,
            }).then(response => {
                this.$notify({
                    title: 'Synced',
                    text: `Campaign was synced with ${form.template.name} successfully`,
                    group: 'app',
                    type: 'success'
                });

                form.sync = response.data;
            }).catch(e => {
                this.$notify({
                    title: 'Sync failed',
                    text: `Unable to sync campaign with ${form.template.platform}. ERR: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            }).finally(() => {
                form.sync.syncing = false;
            });
        },

        formatErrors(result) {
            let errors = {};
            for (const error of result.data.errors) {
                if (error.key.includes('.')) {
                    let errorObj = errors;
                    const path = error.key.split('.');

                    for (const i in path) {
                        const key = path[i];
                        if (Number(i) === path.length - 1) {
                            errorObj[key] = {state: false, message: error.message};
                        } else {
                            if (!errorObj[key]) {
                                errorObj[key] = {};
                            }

                            errorObj = errorObj[key];
                        }
                    }
                } else {
                    errors[error.key] = {state: false, message: error.message};
                }
            }
            return errors;
        },
    }
}
</script>
