<style scoped>
/**/
</style>

<template>
    <main-layout>
        <div class="container-fluid" style="width: 100%; margin-top: 20px; margin-bottom: 30px">
            <h3 class="text-center">Campaign publishing mode</h3>
            <div style="display: flex; justify-content: space-between">
                <div>
                    <b-form-group>
                        <b-form-checkbox-group
                                style="user-select: none"
                                id="checkbox-group-1"
                                v-model="selectedPlatforms"
                                :options="platforms"
                        ></b-form-checkbox-group>
                    </b-form-group>
                </div>
            </div>
            <div v-if="campaign">
                <b-tabs content-class="mt-3">
                    <b-tab v-for="form in platformConfigs" v-bind:key="form.platform">
                        <template #title>
                            {{ platformMap[form.platform] || form.platform }}
                            <span style="padding-left: 10px;" v-if="!form.syncing">
                <font-awesome-icon v-if="campaign.sync[form.platform] && campaign.sync[form.platform].synced" style="color: green" icon="check"></font-awesome-icon>
                <font-awesome-icon v-else style="color: #fd7e14" icon="exclamation-triangle"></font-awesome-icon>
              </span>
                            <span v-else>
                <b-spinner type="border" small></b-spinner>
              </span>
                        </template>
                        <div v-if="form.loading">
            <span style="text-align: center;">
              <b-img style="width: 200px; display: block; margin-left: auto;margin-right: auto;" fluid :src="require('../../images/processing.gif')"/>
            </span>
                        </div>
                        <div v-else style="padding-right: 10%; padding-left: 10%;">
                            <div class="form-group" style="display: flex; flex-direction: row; justify-content: left; gap: 5%">
                                <b-form-checkbox
                                        v-model="form.show_optional"
                                        switch
                                        :unchecked-value="false"
                                        :checked-value="true"
                                >
                                    Show optional
                                </b-form-checkbox>

                                <b-form-group v-if="!form.syncing">
                  <span :style="{color: campaign.sync[form.platform] && campaign.sync[form.platform].synced ? 'green' : 'red'}">
                    <b>{{
                        campaign.sync[form.platform] && campaign.sync[form.platform].synced ? 'Synced' : 'Out of sync'
                        }}</b>. Last sync: {{ campaign.sync[form.platform] && campaign.sync[form.platform].synced_at ? campaign.sync[form.platform].synced_at : 'Never synced' }}. Config updated at: {{
                      campaign.sync[form.platform] && campaign.sync[form.platform].updated_at ? campaign.sync[form.platform].updated_at : 'No config'
                      }} <br/>
                  </span>
                                    <b-button @click=sync(form.platform) size="sm" variant="primary">Sync</b-button>
                                    <b-button style="margin-left: 5px" @click=showSyncLogs(form.platform) size="sm" variant="info">Sync logs</b-button>
                                </b-form-group>
                                <span v-else>
                  Syncing <b-spinner type="border" small></b-spinner>
                </span>

                                <!--                <b-form-checkbox :unchecked-value="false" v-model="saveAsTemplate"  :checked-value="true">Save as template</b-form-checkbox>-->
                                <!--                <v-select-->
                                <!--                    style="width: 300px"-->
                                <!--                    :options="[]"-->
                                <!--                    :reduce="option => option.id"-->
                                <!--                    :multiple="false"-->
                                <!--                    label="label"-->
                                <!--                    v-model="templateId"-->
                                <!--                    placeholder="Choose template or create new one"-->
                                <!--                    :taggable="true"-->
                                <!--                    :create-option="option => {return {id: option, label: option}}"-->
                                <!--                ></v-select>-->

                                <!--    Disabled temporary because of logic issues.   -->
                                <!--          <b-form-checkbox-->
                                <!--              v-model="form.show_prefilled"-->
                                <!--              switch-->
                                <!--              :unchecked-value="false"-->
                                <!--              :checked-value="true"-->
                                <!--          >-->
                                <!--            Show prefilled-->
                                <!--          </b-form-checkbox>-->
                            </div>
                            <FormRender v-on:revalidate="revalidate" :initial-form="form" :form="form" :model="form.model" :errors="form.errors"></FormRender>
                            <div style="display: flex; flex-direction: row; justify-content: left; gap: 1%">
                                <div>
                                    <b-button variant="info" @click="validate(form.platform)">Validate</b-button>
                                </div>
                                <div>
                                    <b-button variant="success" @click="save(form.platform)">Save</b-button>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>

            <b-modal size="xl" ref="syncLogModal" hide-footer title="Sync logs">
                <pre>{{ syncLogs }}</pre>
            </b-modal>

        </div>
    </main-layout>
</template>

<script>
import API from "../../api/Api";
import FormRender from "@/components/FormRender.vue";
import MainLayout from "@/layouts/MainLayout.vue";

export default {
    name: "CampaignPublish",
    components: {
        MainLayout,
        FormRender
    },

    data() {
        return {
            configs: {},
            platforms: [],
            selectedPlatforms: [],
            platformConfigs: [],
            platformMap: {},
            campaign: null,

            errors: [],

            saveAsTemplate: false,
            templateId: null,

            presets: [],
            syncLogs: [],
        };
    },

    mounted() {
        API.get(`/campaign/${this.$route.params.id}`).then(response => {
            this.campaign = response.data;

            if (response.data.platform_configs) {
                this.selectedPlatforms = response.data.enabled_platforms || [];
            }
        });

        API.get('/advertising/platforms-by-feature/createCampaign').then(data => {
            const platformMap = {};
            for (const platform of data.data) {
                this.platforms.push({value: platform.id, text: platform.name});
                platformMap[platform.id] = platform.name;
            }

            this.platformMap = platformMap;
        });
    },

    watch: {
        selectedPlatforms() {
            this.buildForms();
        }
    },

    methods: {
        async buildForms(revalidate = false) {
            for (const idx in this.platformConfigs) {
                const platform = this.platformConfigs[idx].platform;
                if (!this.selectedPlatforms.includes(platform)) {
                    this.platformConfigs.splice(idx, 1);
                }
            }

            for (const platform of this.selectedPlatforms) {
                let config = this.platformConfigs.find(c => c.platform === platform);
                if (config && !revalidate) {
                    continue; // Skip already loaded configs
                }

                if (!config) {
                    this.platformConfigs.push({platform: platform, loading: true});
                }

                const idx = this.platformConfigs.findIndex(c => c.platform === platform);

                let model = null;
                if (this.platformConfigs[idx] && revalidate) {
                    model = this.platformConfigs[idx].model || null;
                }

                API.post(`/campaign/render-config/${this.campaign._id}/${platform}`, {
                    model: model,
                }).then(response => {
                    this.$set(this.platformConfigs, idx, {
                        ...response.data,
                        platform: platform,
                        loading: false,
                        show_optional: false,
                        show_prefilled: true,
                    });
                }).catch(e => {
                    this.$set(this.platformConfigs, idx, {
                        platform: platform,
                        error: e.message,
                        loading: false,
                    });
                })
            }

            this.saveEnabledPlatforms();
        },

        validate(platform) {
            const platformConfig = this.platformConfigs.find(c => c.platform === platform);
            if (!platformConfig) {
                return;
            }

            platformConfig.errors = {};

            API.post('/campaign/validate-publish-config', {
                platform: platform,
                model: platformConfig.model
            }).then(result => {
                if (result.data.success) {
                    this.$notify({
                        title: 'Success',
                        text: `${platform} form is valid!`,
                        group: 'app',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Invalid',
                        text: `${platform} form is invalid!`,
                        group: 'app',
                        type: 'error'
                    });

                    this.processErrors(platform, result);
                }
            }).catch(e => alert(`Validation request failed. ERR: ${e.message}`));
        },

        save(platform) {
            const platformConfig = this.platformConfigs.find(c => c.platform === platform);
            if (!platformConfig) {
                alert(`Platform ${platform} is not selected!`)
            }

            platformConfig.errors = {};

            API.post('/campaign/save-publish-config', {
                platform: platform,
                model: platformConfig.model,
                campaign_id: this.campaign._id,
            }).then(result => {
                if (result.data.success) {
                    this.$notify({
                        title: 'Platform config saved',
                        text: `Config for ${platform} was saved successfully`,
                        group: 'app',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Platform config save failed',
                        text: `Config for ${platform} was not saved`,
                        group: 'app',
                        type: 'error'
                    });
                    this.processErrors(platform, result);
                }
            }).catch(e => alert(`Unable to save config for ${platform}. ERR: ${e.message}`))
                .finally(() => {
                    this.saveEnabledPlatforms();
                    this.updateCampaign();
                });
        },

        saveEnabledPlatforms() {
            API.post('/campaign/save-enabled-platforms', {
                campaign_id: this.campaign._id,
                platforms: this.selectedPlatforms,
            }).then(() => {
            }).catch(e => alert(`Unable to save selected platforms. ERR: ${e.message}`));
        },

        processErrors(platform, result) {
            let errors = {};
            for (const error of result.data.errors) {
                if (error.key.includes('.')) {
                    let errorObj = errors;
                    const path = error.key.split('.');

                    for (const i in path) {
                        const key = path[i];
                        if (Number(i) === path.length - 1) {
                            errorObj[key] = {state: false, message: error.message};
                        } else {
                            if (!errorObj[key]) {
                                errorObj[key] = {};
                            }

                            errorObj = errorObj[key];
                        }
                    }
                } else {
                    errors[error.key] = {state: false, message: error.message};
                }
            }

            const idx = this.platformConfigs.findIndex(c => c.platform === platform);
            this.$set(
                this.platformConfigs,
                idx,
                {
                    ...this.platformConfigs[idx],
                    errors: errors
                }
            );
        },

        async updateCampaign() {
            const response = await API.get(`/campaign/${this.campaign._id}`);
            this.campaign = response.data;
        },

        sync(platform) {
            const platformConfig = this.platformConfigs.find(c => c.platform === platform);
            if (!platformConfig) {
                alert(`Platform ${platform} is not selected!`)
            }

            const idx = this.platformConfigs.findIndex(c => c.platform === platform);
            this.$set(this.platformConfigs, idx, {...this.platformConfigs[idx], syncing: true});

            API.post('/campaign/sync', {
                campaign_id: this.campaign._id,
                platform: platform,
            }).then(() => {
                this.$notify({
                    title: 'Synced',
                    text: `Campaign was synced with ${platform}`,
                    group: 'app',
                    type: 'success'
                });
            }).catch(e => {
                this.$notify({
                    title: 'Sync failed',
                    text: `Unable to sync campaign with ${platform}. ERR: ${e.message}`,
                    group: 'app',
                    type: 'error'
                });
            }).finally(() => {
                this.updateCampaign();
                this.$set(this.platformConfigs, idx, {...this.platformConfigs[idx], syncing: false});
            });
        },

        showSyncLogs(platform) {
            this.syncLogs = {platform: platform};
            this.updateCampaign().then(() => {
                this.syncLogs = (this.campaign.sync_log || []).filter(l => l.platform === platform).sort((a, b) => a.created_at < b.created_at);
            });
            this.$refs.syncLogModal.show();
        },

        revalidate() {
            this.buildForms(true);
        }
    }
}
</script>
