<template>
  <main-layout>
    <div class="container-fluid" v-if="statisticsTable" style="width: 100%; margin-top: 20px">
      <p>Statistics for campaign {{ statisticsCampaign.name }}</p>
      <p>Last pull at {{ statisticsCampaign.statistics_pulled_at }} (Propeller date: {{ statisticsCampaign.statistics_date }})</p>
      <b-button variant="warning" @click="closeStatisticsTable()">Close table</b-button>
      <b-table
          style="margin-top: 20px; width: 100%; font-size: 14px"
          :items="campaignStatistic"
      ></b-table>
    </div>
    <div class="container" v-if="csvForm">
      <h3 class="text-center">Upload campaigns from CSV</h3>
      <div class="form-group">
        <label>Choose CSV file</label>
        <input type="file" id="file" @change="fileUploaded" accept="text/csv"/>
      </div>
      <div class="form-group">
        <b-button @click="bulkMatch(uploadedFileContent)" v-if="uploadedFileContent.length && !bulkIsProcessing" variant="primary">Upload to server</b-button>
        <b-button @click="bulkIsProcessing = false" v-if="bulkIsProcessing" variant="danger">Stop processing</b-button>
        &nbsp;<b-button variant="warning">Cancel</b-button>
      </div>

      <b-table
          style="margin-top: 20px; width: 100%; font-size: 14px"
          :items="uploadedFileContent"
      ></b-table>

    </div>

    <div :style="{display: statisticsTable || csvForm ? 'none': 'block'}" class="container-fluid"
         style="width: 100%; margin-top: 20px">
      <form class="filter-form" v-if="showFilters">
        <div class="form-row">
          <div class="form-group col-md-3" v-for="(value, filter) in filters" v-bind:key="filter">
            <label>{{ filterLabels[filter] }}</label>

            <div v-if="DDL[filter]">
              <b-select v-model="filters[filter]" :options="[ {value: null, text: 'Any'},].concat(DDL[filter])"/>
            </div>
            <div v-else>
              <b-input type="text" v-model="filters[filter]"/>
            </div>
          </div>
        </div>
        <b-button @click="currentPage = 1 && loadData()" variant="primary" type="button">Filter</b-button>
        &nbsp;<b-button @click="resetFilter" variant="warning" type="button">Reset filter</b-button>
        &nbsp;<b-button @click="showFilters = false" variant="info" type="button">Hide
        filters
      </b-button>
        &nbsp;<b-button @click="csvForm=true" variant="primary" type="button">Load from CSV</b-button>
      </form>

      <div class="form-row" v-if="!showFilters">
        <button class="btn btn-info" @click="showFilters = true">Show filters</button>
      </div>
      <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
        <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
      </div>

      <b-table
          style="margin-top: 20px; width: 100%; font-size: 14px"
          :items="data"
          :fields="tableFields"
          striped
          no-local-sorting
          @sort-changed="sort"
      >
        <template v-slot:cell(name)="row">
          <span :style="{color: row.item.status === 'archived' ? 'red' : ''}">{{ row.item.name }}</span>
        </template>
        <template v-slot:cell(binom_campaign_id)="row">
          {{ row.item.binom_campaign_id }} {{ row.item.binom_campaign_name }} <span v-if="row.item.binom_rotation_id">(Rotation ID: <strong>{{
            row.item.binom_rotation_id
          }}</strong>)</span>
        </template>
        <template v-slot:cell(statistics_pulled_at)="row">
          <a style="text-decoration: underline; cursor: pointer" @click="showStatistics(row.item)"
             v-if="row.item.statistics_date">{{ row.item.statistics_date }}</a>
          <span v-else style="text-align: center"> - </span>
        </template>

        <template v-slot:cell(actions)="row">
          <button class="btn btn-primary" @click="mapModal(row.item)">Set binom campaign</button>&nbsp;
          <button v-if="row.item.binom_campaign_id" class="btn btn-danger" @click="unlink(row.item)">Unlink</button>
          <span style="float: right">
            <button class="btn btn-info btn-sm" :disabled="pullingStatistics" @click="pullStatistics(row.item)">Pull statistics</button>&nbsp;
            <button v-if="row.item.binom_campaign_id" class="btn btn-primary btn-sm pull-right" @click="pushStatisticsToBinom(row.item)">Push</button>
          </span>
        </template>

      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mt-4"/>
    </div>
    <b-modal ref="modal" hide-footer title="Match advertising platform and binom campaign IDs">
      <p>Platform: {{ campaignToMatch.platform }}</p>
      <p>Campaign {{ campaignToMatch.id }} {{ campaignToMatch.name }}</p>
      <div class="form-group">
        <label>Binom campaign</label>
        <b-select v-model="matchBinomId" :options="DDL.binom_campaign_id"/>
      </div>
      <div class="form-group">
        <b-button @click="mapCampaigns" class="btn btn-success btn-block">Save</b-button>
      </div>
    </b-modal>
  </main-layout>
</template>

<script>
import api from "../api/Api";
import API from "../api/Api";
import MainLayout from "../layouts/MainLayout";

export default {
  components: {
    MainLayout
  },

  data: () => ({
    data: [],
    showFilters: true,
    csvForm: false,
    campaignToMatch: {},
    matchBinomId: null,
    statisticsTable: false,
    statisticsCampaign: {},
    pullingStatistics: false,
    campaignStatistic: [],
    uploadedFileContent: [],
    bulkIsProcessing: false,
    filters: {
      platform: 'propeller_ads',
      id: null,
      binom_campaign_id: null,
      status: 'active',
    },

    filterLabels: {
      platform: 'Platform',
      id: 'Campaign',
      binom_campaign_id: 'Binom campaign',
      status: 'Status',
    },

    DDL: {
      status: [{value: 'active', text: 'Active'}, {value: 'archived', text: 'Archived'}]
    },

    tableFields: [
      {
        key: 'platform',
        sortable: true,
        label: 'Platform',
      },
      {
        key: 'id',
        sortable: true,
        label: 'Campaign ID',
      },
      {
        key: 'name',
        sortable: true,
        label: 'Campaign Name',
      },
      {
        key: 'binom_campaign_id',
        sortable: true,
        label: 'Binom campaign'
      },
      // {
      //   key: 'binom_campaign_cost',
      //   sortable: false,
      //   label: 'Binom cost (latest)'
      // },
      {
        key: 'statistics_pulled_at',
        sortable: true,
        label: 'Platform statistics date'
      },
      {
        key: 'actions',
        sortable: false,
        label: 'Actions',
      },
      // {
      //   key: 'binom_campaign_id',
      //   sortable: true,
      //   label: 'Binom ID'
      // }
    ],

    sortTable: {
      _id: -1
    },

    itemToShow: {},

    currentPage: 1,
    perPage: Number(window.localStorage.getItem('per-page')) || 10,
    totalRows: 0,
    perPageConfig: [
      {value: 10, text: "Per page: 10"},
      {value: 20, text: "Per page: 20"},
      {value: 30, text: "Per page: 30"},
      {value: 50, text: "Per page: 50"},
      {value: 100, text: "Per page: 100"},
    ],
  }),

  mounted: function () {
    if (this.$route.query.platform) {
      this.filters.platform = this.$route.query.platform;
    }

    this.loadDDL();
    this.loadData();
  },

  watch: {
    currentPage: function () {
      this.loadData();
    },

    perPage: function () {
      window.localStorage.setItem('per-page', this.perPage.toString());
      this.loadData();
    },

    'filters.platform': function (platform) {
      this.loadPlatformCampaigns(platform);
    },
  },

  methods: {
    sort: function (ctx) {
      this.sortTable = {};
      this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
      this.currentPage = 1;

      this.loadData();
    },

    loadData: function () {
      api.post(`/advertising/campaigns?page=${this.currentPage}&per-page=${this.perPage}`, {
        filter: this.filters,
        sort: this.sortTable,
      }).then(data => {
        this.totalRows = Number(data.headers['x-total-rows']) || 0;
        this.data = data.data;
      }).catch(e => this.crypto = e.message);
    },

    resetFilter: function () {
      for (const field in this.filters) {
        this.filters[field] = null;
        this.loadData();
      }
    },

    loadDDL() {
      this.loadPlatformCampaigns(this.filters.platform);
      API.get('/advertising/dropdown/platforms').then(data => this.DDL['platform'] = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
      API.get('/advertising/dropdown/binom-campaigns').then(data => this.DDL['binom_campaign_id'] = data.data.map(item => {
        return {value: item.id, text: `${item.id} - ${item.name}`};
      }));
    },

    loadPlatformCampaigns: function (platform) {
      API.get(`/advertising/dropdown/campaigns?platform_id=${platform}`)
          .then(data => this.DDL['id'] = data.data.map(cmp => {
            return {value: cmp.id, text: cmp.name}
          }));
    },

    showModal() {
      this.$refs.modal.show()
    },
    hideModal() {
      this.campaignToMatch = {};
      this.$refs.modal.hide()
    },

    mapModal(campaign) {
      this.campaignToMatch = campaign;
      this.matchBinomId = campaign.binom_campaign_id;
      this.showModal();
    },

    mapCampaigns() {
      API.post('/advertising/map-campaigns', {
        campaign: this.campaignToMatch._id,
        binom_campaign_id: this.matchBinomId,
      }).then(() => {
        this.hideModal();
        this.loadData();
      }).catch(e => alert(`Request failed with error ${e.message}`));
    },

    unlink(campaign) {
      API.post('/advertising/map-campaigns', {
        campaign: campaign._id,
        binom_campaign_id: null,
      }).then(() => {
        this.loadData();
      }).catch(e => alert(`Request failed with error ${e.message}`));
    },

    pullStatistics(campaign) {
      this.pullingStatistics = true;
      API.post(`/advertising/pull-statistics/${campaign._id}`).then(() => {
        this.loadData();
      }).catch(e => alert(`Request failed with error ${e.message}`))
          .finally(() => this.pullingStatistics = false);
    },

    showStatistics(campaign) {
      this.campaignStatistic = [];
      API.post(`/advertising/campaign-statistics/${campaign._id}`)
          .then(data => {
            this.campaignStatistic = data.data;
            this.statisticsTable = true;
            this.statisticsCampaign = campaign;
          }).catch(e => alert(`Request failed with error ${e.message}`));
    },

    closeStatisticsTable() {
      this.statisticsTable = false;
    },

    pushStatisticsToBinom(campaign) {
      API.post(`/advertising/push-statistics/${campaign._id}`).then(() => {
        alert(`Statistics successfully pushed to binom`);
      }).catch(e => alert(`Unable to push statistics to binom with error ${e.message}`));
    },

    fileUploaded: function () {
      const files = document.getElementById('file').files;
      if (!files.length) {
        alert(`No file uploaded`);
        return;
      }

      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = e => {
          const data = e.target.result.split('\r');

          this.uploadedFileContent = data.map(row => {
            const parts = row.replace('\n', '').split(',');
            if (parts.length === 2) {
              return {
                'binom_id': parts[0].trim(),
                'propeller_id': parts[1].trim(),
                status: 'N/A',
              }
            }
          }).filter(r => !!r);
        }
      }
    },

    async bulkMatch(data) {
      this.bulkIsProcessing = true;

      for (const i in data) {
        if (!this.bulkIsProcessing) {
          continue;
        }

        try {
          if (isNaN(parseInt(data[i].propeller_id))) {
            continue;
          }

          const campaigns = await API.post(`/advertising/campaigns`, {
            filter: {
              id: Number(data[i].propeller_id),
            }
          });

          if (!campaigns.data.length) {
            data[i].status = `Propeller campaign ${data[i].propeller_id} not exists in db`;
            continue;
          }

          if (campaigns.data[0].binom_campaign_id) {
            if (campaigns.data[0].binom_campaign_id !== Number(data[i].binom_id)) {
              data[i].status = `Propeller campaign ${data[i].propeller_id} already status to binom ID ${campaigns.data[0].binom_campaign_id}`;
            } else {
              data[i].status = `Up to date`;
            }
          } else {
            data[i].status = `Updating...`;
            const result = await API.post('/advertising/map-campaigns', {
              campaign: campaigns.data[0]._id,
              binom_campaign_id: Number(data[i].binom_id),
            });

            data[i].status = `Updated. Status code ${result.status}`;
          }

        } catch (e) {
          data[i].status = `ERROR: ${e.message}`;
        }
      }
    }
  }
}
</script>
