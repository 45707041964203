<style scoped>
.btn {
  margin-left: 5px;
}
</style>

<template>
  <main-layout>
    <div class="container" style="width: 100%; margin-top: 20px">
      <div>
        <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
          <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
        </div>
        <router-link to="/creatives/new">
          <button style="float: right; margin-top: 2px" class="btn btn-success btn-sm">Add new creative</button>
        </router-link>
        <b-table
            style="margin-top: 20px; width: 100%; font-size: 14px"
            :items="data"
            :fields="tableFields"
            striped
            no-local-sorting
            @sort-changed="sort"
        >
          <template v-slot:cell(actions)="row">
            <router-link :to="'/creatives/' + row.item._id">
              <button class="btn btn-primary btn-sm">
                <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
              </button>
            </router-link>
            <button class="btn btn-danger btn-sm" @click="remove(row.item)">
              <font-awesome-icon icon="trash"></font-awesome-icon>
            </button>
            <button class="btn btn-info btn-sm" @click="requestClone(row.item)">
              <font-awesome-icon icon="clone"></font-awesome-icon>
            </button>
          </template>
          <template v-slot:cell(icon)="row">
            <div v-if="row.item.icon">
              <img alt="Icon" :src="row.item.icon" style="max-width: 10%; margin-top: 10px;"/>
            </div>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="mt-4"/>
      </div>
      <b-modal size="xl" ref="cloneModal" hide-footer title="Enter new creative name">
        <b-form-group>
          <b-form-input v-model="clone.name" placeholder="Enter new creative name"></b-form-input>
        </b-form-group>
        <b-form-group style="display: flex; justify-content: left;">
          <b-button variant="success" @click="doClone">Clone</b-button>
          <b-button style="margin-left: 5px" variant="outline-warning" @click="cancelClone">Cancel</b-button>
        </b-form-group>
      </b-modal>
    </div>
  </main-layout>
</template>

<script>

import API from "../../api/Api";
import MainLayout from "../../layouts/MainLayout";

export default {
  name: "CreativesList",
  components: {
    MainLayout
  },

  data: () => ({
    data: [],
    clone: {},
    tableFields: [
      {
        key: 'creative_name',
        sortable: false,
        label: 'Internal name',
      },
      {
        key: 'name',
        sortable: false,
        label: 'Headline',
      },
      {
        key: 'description',
        sortable: false,
        label: 'Creative name',
      },
      {
        key: 'icon',
        sortable: false,
        label: 'Icon 192x192',
      },
      {
        key: 'actions',
        sortable: false,
        label: 'Actions',
      }
    ],

    sortTable: {_id: -1},

    // Pagination
    currentPage: 1,
    perPage: Number(window.localStorage.getItem('per-page')) || 10,
    totalRows: 0,
    perPageConfig: [
      {value: 10, text: "Per page: 10"},
      {value: 20, text: "Per page: 20"},
      {value: 30, text: "Per page: 30"},
      {value: 50, text: "Per page: 50"},
      {value: 100, text: "Per page: 100"},
    ],
  }),

  mounted: function () {
    this.loadData();
  },

  watch: {
    currentPage: function () {
      this.loadData();
    },

    perPage: function () {
      window.localStorage.setItem('per-page', this.perPage.toString());
      this.loadData();
    }
  },

  methods: {
    sort: function (ctx) {
      this.sortTable = {};
      this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
      this.currentPage = 1;

      this.loadData();
    },

    loadData: function () {
      API.post(`/creatives/list?page=${this.currentPage}&per-page=${this.perPage}`, {
        //filter: this.filters,
        sort: this.sortTable,
      }).then(data => {
        this.totalRows = Number(data.headers['x-total-rows']) || 0;
        this.data = data.data;
      }).catch(e => this.error = e.message);
    },

    remove(model) {
      if (confirm(`Are you sure you want to delete creative ${model.name}?`) === false) {
        return;
      }

      API.delete(`/creatives/${model._id}`).then(() => this.loadData()).catch(e => alert(`Unable to delete creative. Server error: ${e.message}`));
    },

    requestClone(model) {
      this.clone = {
        name: model.name + ' CLONE',
        description: model.description,
        icon: model.icon,
      }

      this.$refs.cloneModal.show();
    },

    doClone() {
      if (!this.clone || !this.clone.name) {
        alert('Please enter a headline for the new creative');
        return;
      }

      API.post(`/creatives`, this.clone).then(() => {
        this.$refs.cloneModal.hide();
        this.$notify({
          title: 'Success',
          text: `Creative cloned successfully`,
          group: 'app',
          type: 'success'
        });
        this.loadData();
      }).catch(e => {
        this.$notify({
          title: 'Invalid',
          text: `Unable to clone creative with error ${e.message}`,
          group: 'app',
          type: 'error'
        });
      });
    },

    cancelClone() {
      this.clone = {};
      this.$refs.cloneModal.hide();
    }
  }
}
</script>
