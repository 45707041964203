<template>
    <main-layout>
        <div class="container-fluid" style="width: 100%; margin-top: 20px">
            <form class="filter-form" v-if="showFilters">
                <div class="form-row">
                    <div class="form-group col-md-3" v-for="(value, filter) in filters" v-bind:key="filter">
                        <label>{{filterLabels[filter]}}</label>

                        <div v-if="DDL[filter]">
                            <b-select v-model="filters[filter]"
                                      :options="[ {value: null, text: 'Any'},].concat(DDL[filter])"/>
                        </div>
                        <div v-else>
                            <b-input type="text" v-model="filters[filter]"/>
                        </div>
                    </div>
                </div>
                <b-button @click="currentPage = 1 && loadData()" variant="primary" type="button">Filter</b-button>
                &nbsp;<b-button @click="resetFilter" variant="warning" type="button">Reset filter</b-button>
                &nbsp;<b-button @click="showFilters = false" variant="info" type="button">Hide
                filters
            </b-button>
            </form>

            <div class="form-row" v-if="!showFilters">
                <button class="btn btn-info" @click="showFilters = true">Show filters</button>
            </div>
            <h4 class="text-center pull-right" v-if="!!avgSellDays">Average days to sold: {{avgSellDays}}</h4>

            <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
                <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
            </div>

            <b-table
                    style="margin-top: 20px; width: 100%; font-size: 14px"
                    :items="data"
                    :fields="tableFields"
                    striped
                    no-local-sorting
                    @sort-changed="sort"
            >
                <template v-slot:cell(title)="row">
                    <a style="cursor: pointer" @click="showItem(row.item)"> {{row.item.title}}</a>
                </template>
                <template v-slot:cell(price)="row">
                    {{new Intl.NumberFormat('en-US', {style: 'currency', 'currency': 'EUR'}).format(row.item.price)}}
                </template>
                <template v-slot:cell(mileage)="row">
                    {{new Intl.NumberFormat('en-US').format(row.item.mileage)}}
                </template>
            </b-table>
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="mt-4"/>

            <b-modal ref="removeModalRef" hide-footer :title="itemToShow.title" size="xl">
                <img :src="itemToShow.imageUrls && itemToShow.imageUrls.length ? itemToShow.imageUrls[0] : null"
                     style="float:left; padding-right: 10px"/>
                <h3>
                    <a target="_blank" rel="nofollow" :href="'https://marktplaats.nl' + itemToShow.vipUrl">{{itemToShow.title}}</a>
                </h3>
                <pre>
                {{itemToShow}}
            </pre>
            </b-modal>

        </div>
    </main-layout>
</template>

<script>
    import api from "../api/Api";
    import MainLayout from "../layouts/MainLayout";

    export default {
        components: {
            MainLayout
        },

        data: () => ({
            data: [],
            avgSellDays: null,
            showFilters: true,
            filters: {
                model: null,
                carrosserie: null,
                brandstof: null,
                transmissie: null,
                kleur: null,
                import_auto: null,

                constructionYearMin: null,
                constructionYearMax: null,
                aantalDeurenMin: null,
                aantalDeurenMax: null,
                mileageMin: null,
                mileageMax: null,
                motorinhoudMin: null,
                motorinhoudMax: null,
                vermogenMin: null,
                vermogenMax: null,
                priceMin: null,
                priceMax: null,
                sold: null,
            },

            filterLabels: {
                model: 'Model',
                carrosserie: 'Carrosserie',
                brandstof: 'Brandstof',
                transmissie: 'Transmissie',
                kleur: 'Kleur',
                import_auto: 'Importauto',

                constructionYearMin: 'Min construction year',
                constructionYearMax: 'Max construction year',
                aantalDeurenMin: 'Min aantal deuren',
                aantalDeurenMax: 'Max aantal deuren',
                mileageMin: 'Min mileage',
                mileageMax: 'Max mileage',
                motorinhoudMin: 'Min motorinhoud',
                motorinhoudMax: 'Max motorinhoud',
                vermogenMin: 'Min vermogen',
                vermogenMax: 'Max vermogen',
                priceMin: 'Min price (€)',
                priceMax: 'Max price (€)',
                sold: 'Sold status',
            },
            DDL: {
                model: [],
                carrosserie: [],
                brandstof: [],
                transmissie: [],
                kleur: [],
                import_auto: [],
                sold: [
                    {value: false, text: 'Active only'},
                    {value: true, text: 'Sold only'},
                ],
            },

            tableFields: [
                {
                    key: 'title',
                    sortable: true,
                    label: 'Name',

                },
                {
                    key: 'inline.model',
                    sortable: true,
                    label: 'Model',
                },
                {
                    key: 'constructionYear',
                    sortable: true,
                    label: 'Year',

                },
                {
                    key: 'mileage',
                    sortable: true,
                    label: 'Mileage',
                },
                {
                    key: 'inline.carrosserie',
                    sortable: true,
                    label: 'Carrosserie',
                },
                {
                    key: 'inline.brandstof',
                    sortable: true,
                    label: 'Brandstof',
                },
                {
                    key: 'inline.transmissie',
                    sortable: true,
                    label: 'Transmissie',
                },
                {
                    key: 'inline.kleur',
                    sortable: true,
                    label: 'Kleur',
                },
                {
                    key: 'custom_options.import_auto',
                    sortable: true,
                    label: 'Import',
                },
                {
                    key: 'inline.aantal_deuren',
                    sortable: true,
                    label: 'Aantal deuren',
                },
                {
                    key: 'inline.motorinhoud',
                    sortable: true,
                    label: 'Motorinhoud',
                },
                {
                    key: 'inline.vermogen',
                    sortable: true,
                    label: 'Vermogen',
                },
                {
                    key: 'posted_at',
                    sortable: true,
                    label: 'Posted date',
                },
                {
                    key: 'price',
                    sortable: true,
                    label: 'Price',
                },
                {
                    key: 'total_exists_days',
                    sortable: true,
                    label: 'Total days exist',
                },
            ],

            sortTable: {
                _id: -1
            },

            itemToShow: {},

            currentPage: 1,
            perPage: Number(window.localStorage.getItem('per-page')) || 10,
            totalRows: 0,
            perPageConfig: [
                {value: 10, text: "Per page: 10"},
                {value: 20, text: "Per page: 20"},
                {value: 30, text: "Per page: 30"},
                {value: 50, text: "Per page: 50"},
                {value: 100, text: "Per page: 100"},
            ],
        }),

        mounted: function () {
            this.loadDDL();
            this.loadData();
        },

        watch: {
            currentPage: function () {
                this.loadData();
            },

            perPage: function () {
                window.localStorage.setItem('per-page', this.perPage.toString());
                this.loadData();
            }
        },

        methods: {
            sort: function (ctx) {
                this.sortTable = {};
                this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                this.currentPage = 1;

                this.loadData();
            },

            loadData: function () {
                api.post(`/mp/items/${this.$route.params.id}?page=${this.currentPage}&per-page=${this.perPage}`, {
                    filter: this.filters,
                    sort: this.sortTable,
                }).then(data => {
                    this.totalRows = Number(data.headers['x-total-rows']) || 0;
                    const result = data.data;

                    this.data = result.data;
                    this.avgSellDays = result.avgSellDays;

                }).catch(e => this.crypto = e.message);
            },

            resetFilter: function () {
                for (const field in this.filters) {
                    this.filters[field] = null;
                    this.loadData();
                }
            },

            loadDDL() {
                for (const field in this.DDL) {
                    api.get(`/mp/dropdown/${field}?link_id=${this.$route.params.id}`).then(response => {
                        this.DDL[field] = response.data && response.data.length ? response.data : this.DDL[field];
                    });
                }
            },

            showItem(item) {
                this.itemToShow = item;
                this.showModal();
            },

            showModal() {
                this.$refs.removeModalRef.show()
            },

            hideModal() {
                this.$refs.removeModalRef.hide()
            },
        }
    }
</script>