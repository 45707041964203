<template>
    <main-layout>
        <div class="container">
            <h2 class="text-center">Settings</h2>
            <div class="form-group">
                <button class="btn btn-primary" @click="clearRedisCache">Clear redis cache</button>
            </div>
        </div>
    </main-layout>
</template>
<script>
    import API from "../api/Api";
    import MainLayout from "../layouts/MainLayout";

    export default {
        components: {
            MainLayout
        },
        data: () => ({
        }),

        mounted: function () {

        },

        methods: {
            clearRedisCache: function () {
                API.post('/settings/clear-cache').then(() => {
                    alert('Done!');
                }).catch(e => alert(`Unable to clear cache with error ${e.message}`));
            }
        }
    }
</script>
