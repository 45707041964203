<style scoped>
.input-form {
    padding: 5px;
    margin-bottom: 10px;
}

.form-border {
    border: 1px solid gray;
}

.inline-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.inline-inputs button {
    margin-top: 12px;
}

.form-group {
    width: 100%;
}

.add-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.remove-button {
    margin-top: 10px;
    /*margin-bottom: 10px;*/
}

</style>

<template>
    <div :class="inline ? 'inline-inputs' : ''">
        <div v-for="(input, name) in form.input" v-bind:key="name" :style="{width: input.width}">
            <div v-if="input.required || initialForm.show_optional">
                <div v-if="input.input" :class="form.border === false ? 'input-form' : 'input-form form-border'">
                    <h5 style="text-align: center" v-if="input.label">{{ input.label }}</h5>
                    <FormRender v-on:revalidate="revalidate" :initial-form="initialForm" :inline="!!input.inline" :form="input" :model="model[name] || {}"
                                :errors="errors[name] || {}" :template-mode="templateMode"></FormRender>
                </div>

                <div v-else-if="input.inputs" :class="form.border === false ? 'input-form' : 'input-form form-border'">
                    <h5 style="text-align: center">{{ input.label }} {{ model[name] && Array.isArray(model[name]) ? '(' + model[name].length + ')' : '' }}</h5>

                    <div v-bind:key="i" v-for="(item, i) in model[name] || []" :class="input.inline ? 'inline-inputs' : ''">
                        <FormRender
                                v-on:revalidate="revalidate"
                                :initial-form="initialForm"
                                :inline="input.inline"
                                :form="{input: input.inputs[0]}" :model="item"
                                :errors="errors[name] ? errors[name][i] || {} : {}"
                                :template-mode="templateMode"
                        ></FormRender>
                        <button class="btn btn-danger pull-right remove-button" @click="model[name].splice(i, 1)">Remove</button>
                        <span style="color: red" v-if="errors[name] && errors[name][i]">{{ errors[name][i].message }}</span>
                    </div>

                    <button class="btn btn-success add-button" @click="model[name].push({})">Add</button>
                </div>

                <b-form-group v-else>
                    <label v-if="input.type !== 'checkbox'">
                        {{ input.label }} <span style="color: red" v-if="input.required">*</span>
                        <b-form-checkbox
                                v-if="templateMode && (!model[name] || (Array.isArray(model[name]) && !model[name].length) || model[name] === '__excluded__')"
                                switch
                                style="font-style: italic"
                                v-model="input.__excluded__"
                                @change="toggleValidationExcluded($event, name)"
                        >
                            Required to set in campaign config
                        </b-form-checkbox>
                    </label>

                    <div v-if="model[name] !== '__excluded__' || !templateMode">
                        <b-form-input
                                type="text" v-if="input.type === 'text'"
                                :maxlength="input.max || null"
                                :minlength="input.min || null" class="form-control"
                                v-model="model[name]"
                                :state="errors[name] ? errors[name].state : null"
                        />
                        <b-form-textarea
                                v-if="input.type === 'textarea'"
                                :maxlength="input.max || null"
                                :minlength="input.min || null" class="form-control"
                                v-model="model[name]"
                                :state="errors[name] ? errors[name].state : null"
                                :rows="input.rows || 3"
                        />
                        <b-form-input
                                type="number"
                                v-if="input.type === 'number'"
                                :step="input.step || 0.1"
                                :min="input.min || null"
                                :max="input.max || null"
                                class="form-control"
                                v-model="model[name]"
                                :state="errors[name] ? errors[name].state : null"
                        />
                        <b-form-input
                                type="date"
                                v-if="input.type === 'date'"
                                :min="input.min || null"
                                :max="input.max || null"
                                class="form-control"
                                v-model="model[name]"
                                :state="errors[name] ? errors[name].state : null"
                        />
                        <v-select
                                v-if="input.type === 'select'"
                                :options="input.options"
                                :reduce="option => option.id"
                                :multiple="!!input.multiple"
                                label="label"
                                v-model="model[name]"
                                :taggable="!!input.custom_values"
                                :create-option="option => {return {id: option, label: option}}"
                                @input="input.revalidate && revalidate()"
                        ></v-select>
                        <label v-if="input.type === 'checkbox'">
                            {{ input.label }}
                            <b-form-checkbox
                                    v-if="input.type === 'checkbox'"
                                    v-model="model[name]"
                                    :unchecked-value="false"
                                    :checked-value="true"
                                    switch
                                    :state="errors[name] ? errors[name].state : null">
                            </b-form-checkbox>
                        </label>
                        <div v-if="input.type === 'image'">
                            <b-form-file
                                    class="form-control"
                                    :state="errors[name] ? errors[name].state : null"
                                    v-model="files[name]"
                                    :accept="input.formats.map(f => '.' + f).join(',')"
                            />
                            <div v-if="model[name]">
                                <img :alt="name" :src="model[name]" style="max-width: 10%; margin-top: 10px;"/>
                                <button
                                        class="rm-btn"
                                        style="margin-left: 5px; width: 30px; height: 30px; background-color: red; border-radius: 50%; border: none; color: white"
                                        @click="model[name] = null; files[name] = null">X
                                </button>
                            </div>
                        </div>
                    </div>

                    <span style="color: red" v-if="errors[name] && !input.__excluded__">{{ errors[name].message }}</span>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FormRender",
    components: {},
    emits: ['revalidate'],
    props: {
        form: Object,
        model: Object,
        errors: Object,
        initialForm: Object,
        inline: Boolean,
        templateMode: Boolean,
    },

    data() {
        return {
            files: {},
        };
    },

    watch: {
        files: {
            handler() {
                for (const name in this.files) {
                    if (this.files[name] instanceof File) {
                        // Get base64 from files[name] File object
                        const reader = new FileReader();
                        reader.readAsDataURL(this.files[name]);
                        reader.onload = () => {
                            this.model[name] = reader.result;
                        };
                    }
                }
            },
            deep: true
        }
    },

    methods: {
        revalidate: function () {
            this.$emit('revalidate');
        },
        toggleValidationExcluded(excluded, name) {
            this.$set(this.model, name, excluded ? '__excluded__' : null);
        }
    }
}
</script>
