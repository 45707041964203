import axios from 'axios'

const API = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    timeout: 180000, // 180 sec.
    headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('auth_token')}`,
        'Content-Type': 'application/json'
    }
});

API.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        window.localStorage.removeItem('auth_token');
        window.location.href = '/login';
    }

    throw error;
});

export default API
