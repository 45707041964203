<template>
  <main-layout>
    <div class="container-fluid" style="width: 100%; margin-top: 20px">
      <form class="filter-form" v-if="showFilters">
        <div class="form-row">
          <div class="form-group col-md-3" v-for="(value, filter) in filters" v-bind:key="filter">
            <label>{{ filterLabels[filter] }}</label>

            <div v-if="DDL[filter]">
              <b-select v-model="filters[filter]"
                        :options="[ {value: null, text: 'Any'},].concat(DDL[filter])"/>
            </div>
            <div v-else>
              <b-input type="text" v-model="filters[filter]"/>
            </div>
          </div>
        </div>
        <b-button @click="currentPage = 1 && loadData()" variant="primary" type="button">Filter</b-button>
        &nbsp;<b-button @click="resetFilter" variant="warning" type="button">Reset filter</b-button>
        &nbsp;<b-button @click="showFilters = false" variant="info" type="button">Hide
        filters
      </b-button>
      </form>

      <div class="form-row" v-if="!showFilters">
        <button class="btn btn-info" @click="showFilters = true">Show filters</button>
      </div>
      <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
        <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
      </div>

      <b-table
          style="margin-top: 20px; width: 100%; font-size: 14px"
          :items="data"
          :fields="tableFields"
          striped
          no-local-sorting
          @sort-changed="sort"
      >
        <template v-slot:cell(performance)="row">
                    <span :style="{color: numberColorHelper(row.item.performance)}">
                        {{ formatNumberHelper(row.item.performance) }}
                    </span>
        </template>

        <template v-slot:cell(carrier_name)="row">
          &nbsp;<router-link :to="'/advertising-chart?carrier='+row.item.carrier_name">{{ row.item.carrier_name }}
        </router-link>
        </template>
        <template v-slot:cell(country_name)="row">
          &nbsp;<router-link :to="'/advertising-chart?country_iso='+row.item.country_iso">{{ row.item.country_name }}
        </router-link>
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mt-4"/>
    </div>
  </main-layout>
</template>

<script>
import api from "../api/Api";
import MainLayout from "../layouts/MainLayout";
import API from "../api/Api";

export default {
  components: {
    MainLayout
  },

  data: () => ({
    data: [],
    showFilters: true,
    filters: {
      country_iso: null,
      carrier_name: null,
      
      min_ecpm_24h: null,
      max_ecpm_24h: null,
      min_ecpm_recent: null,
      max_ecpm_recent: null,
      
      min_ecpc_24h: null,
      max_ecpc_24h: null,
      min_ecpc_recent: null,
      max_ecpc_recent: null,
      min_acpa_24h_eur: null,
      max_acpa_24h_eur: null,
      
      source_platform: 'v1',
    },

    filterLabels: {
      country_iso: 'Country',
      carrier_name: 'Carrier',
 
      min_ecpm_24h: 'Min eCPM 24h',
      max_ecpm_24h: 'Max eCPM 24h',
      min_ecpm_recent: 'Min eCPM recent',
      max_ecpm_recent: 'Max eCPM recent',

      min_ecpc_24h: 'Min eCPC 24h',
      max_ecpc_24h: 'Max eCPC 24h',
      min_ecpc_recent: 'Min eCPC recent',
      max_ecpc_recent: 'Max eCPC recent',
      
      min_acpa_24h_eur: 'Min acpa_24h',
      max_acpa_24h_eur: 'Max acpa_24h',

      source_platform: 'Data source'
    },

    DDL: {},

    tableFields: [
      {
        key: 'carrier_name',
        sortable: true,
        label: 'Carrier',
      },
      {
        key: 'country_name',
        sortable: true,
        label: 'Country',
      },
      {
        key: 'performance',
        sortable: true,
        label: 'Performance',
      },
      {
        key: 'ecpm_24h',
        sortable: true,
        label: 'eCPM 24h',
      },
      {
        key: 'ecpm_recent',
        sortable: true,
        label: 'eCPM recent',
      },
      {
        key: 'ecpc_24h',
        sortable: true,
        label: 'eCPC 24h',
      },

      {
        key: 'ecpc_recent',
        sortable: true,
        label: 'eCPC recent',
      },
      {
        key: 'acpa_24h_eur',
        sortable: true,
        label: 'ACPA 24h'
      },
      {
        key: 'updated_at',
        sortable: true,
        label: 'Updated date',
      }
    ],

    sortTable: {
      _id: -1
    },

    itemToShow: {},

    currentPage: 1,
    perPage: Number(window.localStorage.getItem('per-page')) || 10,
    totalRows: 0,
    perPageConfig: [
      {value: 10, text: "Per page: 10"},
      {value: 20, text: "Per page: 20"},
      {value: 30, text: "Per page: 30"},
      {value: 50, text: "Per page: 50"},
      {value: 100, text: "Per page: 100"},
    ],
  }),

  mounted: function () {
    if (this.$route.query.country_iso_code) {
      this.filters.country_iso = this.$route.query.country_iso_code;
    }

    if (this.$route.query.carrier_name) {
      this.filters.carrier_name = this.$route.query.carrier_name;
    }

    this.loadDDL();
    this.loadData();
  },

  watch: {
    currentPage: function () {
      this.loadData();
    },

    perPage: function () {
      window.localStorage.setItem('per-page', this.perPage.toString());
      this.loadData();
    }
  },

  methods: {
    sort: function (ctx) {
      this.sortTable = {};
      this.sortTable[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
      this.currentPage = 1;

      this.loadData();
    },

    loadData: function () {
      api.post(`/advertising/source-data?page=${this.currentPage}&per-page=${this.perPage}`, {
        filter: this.filters,
        sort: this.sortTable,
      }).then(data => {
        this.totalRows = Number(data.headers['x-total-rows']) || 0;
        this.data = data.data;
      }).catch(e => this.crypto = e.message);
    },

    resetFilter: function () {
      for (const field in this.filters) {
        this.filters[field] = null;
        this.loadData();
      }
    },

    loadDDL() {
      api.get('/advertising/dropdown/countries').then(data => this.DDL['country_iso'] = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
      API.get('/advertising/dropdown/source-platforms').then(data => this.DDL['source_platform'] = data.data.map(item => {
        return {value: item.id, text: item.name};
      }));
      api.get(`/advertising/dropdown/carriers`)
          .then(data => this.DDL['carrier_name'] = data.data.map(carrier => {
            return {value: carrier.id, text: carrier.name}
          }));
    },

    formatNumberHelper(number) {
      if (number > 0) {
        return `+ ${number}`
      }

      return number;
    },

    numberColorHelper(number) {
      if (number === 0) {
        return '';
      }

      return number > 0 ? 'green' : 'red';
    }
  }
}
</script>