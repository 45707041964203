<style scoped>
.filter-group {
  padding-left: 8px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <main-layout>
    <div class="container-fluid" style="padding-right: 120px; padding-left: 120px;">
      <h2 class="text-center">Advertising monitor</h2>
      <div v-if="!form">

        <div class="row">
          <div class="col-md-3">
            <label>Country</label>
            <b-select @change="loadCarriers(filters.country_iso_code)" v-model="filters.country_iso_code"
                      :options="[{value: null, text: 'Any'}].concat(countries)"></b-select>
          </div>
          <div class="col-md-3">
            <label>Carrier</label>
            <b-select v-model="filters.triggers.carrier"
                      :options="[{value: null, text: 'Any'}].concat(carriers)"></b-select>
          </div>

          <div class="col-md-3">
            <label>Platform</label>
            <b-select @change="loadCampaigns(filters.platform)" v-model="filters.platform"
                      :options="[{value: null, text: 'Any'}].concat(platforms)"></b-select>
          </div>
          <div class="col-md-3">
            <label>Campaign</label>
            <b-select v-model="filters.campaign"
                      :options="[{value: null, text: 'Any'}].concat(campaigns)"></b-select>
          </div>
          <div class="col-md-3">
            <label>Data source</label>
            <b-select v-model="filters.source_platform"
                      :options="[{value: null, text: 'Any'}].concat(source_platforms)"></b-select>
          </div>
          <div class="col-md-3">
            <label>Action</label>
            <b-select v-model="filters.triggers.action"
                      :options="[{value: null, text: 'Any'}].concat(actions)"></b-select>
          </div>
          <div class="col-md-3">
            <label>Name</label>
            <b-input v-model="filters.name"></b-input>
          </div>

          <div class="col-md-12" style="padding-top: 30px">
            <button @click="form=true" class="btn btn-success">Add new monitor</button>
            &nbsp;<b-button @click="loadMonitors" variant="primary">Filter</b-button>
          </div>
        </div>

        <div>
          <div class="form-group col-md-2 col-sm-3 col-xs-2" style="float:right;">
            <b-select id="per-page-input" v-model="perPage" :options="perPageConfig"/>
          </div>
          <span v-if="loading" style="text-align: center;">
            <b-img style="width: 200px; display: block; margin-left: auto;margin-right: auto;" fluid
                   :src="require('../images/processing.gif')"/>
          </span>
          <b-table v-else style="margin-top: 20px;" :items="monitors" :fields="tableFields" striped>
            <template v-slot:cell(campaign_name)="row">
              <code>{{row.item.campaign}}</code> {{ row.item.campaign_name }}
            </template>
            <template v-slot:cell(triggers)="row">
              {{ row.item.triggers.length }}
            </template>
            <template v-slot:cell(country_name)="row">
              <router-link :to="'/advertising-source-data?country_iso_code='+row.item.country_iso_code">
                {{ row.item.country_name }}
              </router-link>
            </template>
            <template v-slot:cell(status)="row">
              <b-button size="sm" @click="pause(row.item)" v-if="row.item.status === 'active'"
                        variant="success">Pause
              </b-button>
              <b-button size="sm" @click="run(row.item)" v-else variant="secondary">Run</b-button>
            </template>

            <template v-slot:cell(options)="row">
              <button class="btn btn-danger btn-sm" @click="remove(row.item._id)">Remove</button>
              &nbsp;
              <button class="btn btn-primary btn-sm" @click="edit(row.item)">Edit</button>
              &nbsp;
              <button class="btn btn-info btn-sm" @click="logs(row.item)">Logs</button>
              &nbsp;<button class="btn btn-warning btn-sm" @click="copy(row.item)">Copy</button>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mt-4"/>
        </div>
      </div>

      <div v-else>
        <b-form>
          <b-form-group id="name" label="Name" label-for="name">
            <b-form-input
                :state="errors.name.state"
                id="nameInput"
                v-model="monitor.name"
                type="text"
                aria-describedby="nameError"
                required
            />
            <b-form-invalid-feedback id="nameError">{{ errors.name.message }}</b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-form-group class="col-md-4" id="platform" label="Platform" label-for="platform">
              <b-select @change="loadCampaigns()" v-model="monitor.platform"
                        :options="platforms"
                        :state="errors.platform.state"
                        aria-describedby="platformError"
                        required
              />
              <b-form-invalid-feedback id="platformError">{{ errors.platform.message }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-4" id="campaign" label="Campaign" label-for="campaign"
                          :description="monitor.platform ? '' : 'Please platform first'">
              <b-select v-model="monitor.campaign"
                        :options="campaigns"
                        :state="errors.campaign.state"
                        aria-describedby="carrierError"
                        required
                        :disabled="!monitor.platform"
              />
              <b-form-invalid-feedback id="carrierError">{{ errors.campaign.message }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-4" id="source-platform" label="Data source" label-for="source-platform">
              <b-select v-model="monitor.source_platform"
                        :options="source_platforms"
                        :state="errors.source_platform.state"
                        aria-describedby="carrierError"
                        required
              />
              <b-form-invalid-feedback id="carrierError">{{ errors.source_platform.message }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-4" id="country" label="Country" label-for="country">
              <b-select @change="loadCarriers()" v-model="monitor.country_iso_code"
                        :options="countries"
                        :state="errors.country_iso_code.state"
                        aria-describedby="countryError"
                        required
              />
              <b-form-invalid-feedback id="country_iso_codeError">{{ errors.country_iso_code.message }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-row>

          <hr/>
          <h5 class="text-center">Triggers</h5>

          <b-row v-for="(trigger, index) in monitor.triggers" v-bind:key="index">
            <b-form-group class="col-md-3" label="Carrier"
                          :description="monitor.country_iso_code ? '' : 'Please select country first'">
              <multiselect v-model="trigger.carrier"
                           :disabled="!monitor.country_iso_code"
                           :multiple="true"
                           :close-on-select="false"
                           :clear-on-select="false"
                           :preserve-search="true"
                           :options="carriers"
                           :searchable="true"
                           :allow-empty="true"
                           placeholder="Select one"
              >
              </multiselect>
              <b-form-radio-group v-if="trigger.carrier.length > 1" v-model="trigger.carrier_match"
                                  :options="[{value: 'all', 'text': 'ALL'}, {value: 'any', text: 'ANY'}]"></b-form-radio-group>
            </b-form-group>
            <b-form-group class="col-md-3" label="Field">
              <b-select v-model="trigger.field" :options="fields"/>
            </b-form-group>
            <b-form-group class="col-md-1" label="Operator">
              <b-select v-model="trigger.operator" :options="operators"/>
            </b-form-group>
            <b-form-group class="col-md-1" label="Value">
              <b-form-input v-model="trigger.value" type="number"/>
            </b-form-group>
            <b-form-group class="col-md-1" label="Factor">
              <b-form-input v-model="trigger.factor" type="number"/>
            </b-form-group>
            <b-form-group class="col-md-2" label="Action">
              <b-select v-model="trigger.action" :options="actions"/>
              <b-checkbox v-model="trigger.with_alert">With alert</b-checkbox>
            </b-form-group>
            <b-form-group>
              <b-button @click="removeTrigger(index)" style="margin-top: 50%" size="sm" variant="danger">Remove
              </b-button>
            </b-form-group>
          </b-row>
          <b-alert variant="danger" :show="errors.triggers.state === false">{{ errors.triggers.message }}</b-alert>

          <b-row>
            <b-form-group style="padding-left: 10px">
              <b-button @click="addTrigger" variant="success">Add trigger</b-button>
            </b-form-group>
          </b-row>
          <b-row style="padding: 15px" v-if="!processing">
            <div style="display: flex; gap: 5px;">
            <b-button type="button" @click="saveMonitor" variant="primary">Save</b-button>
            <button @click="cancelEdit" type="button" class="btn btn-warning">Cancel</button>
            </div>
          </b-row>
          <p v-if="processing">
            <b-img style="width: 100px" fluid :src="require('../images/processing.gif')"/>
            Processing ...
          </p>
        </b-form>
      </div>

      <b-modal ref="removeModalRef" hide-footer title="Remove monitor">
        <div class="d-block text-center">
          <h3>Remove this monitor and all relates items?</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="doRemove">Remove</b-button>
        <b-button class="mt-3" variant="outline-warning" block @click="hideModal">Cancel</b-button>
      </b-modal>

      <b-modal size="lg" ref="logsModalRef" hide-footer title="Monitor logs">
        <div class="d-block text-center">
          <p v-for="log in monitorLogs" v-bind:key="log._id">{{ log.created_at }} {{ log.message }}</p>
        </div>
      </b-modal>

    </div>
  </main-layout>
</template>
<script>
import API from "../api/Api";
import MainLayout from "../layouts/MainLayout";

export default {
  components: {
    MainLayout
  },
  data: () => ({
    form: false,
    monitors: [],
    processing: false,
    platforms: [],
    campaigns: [],
    countries: [],
    carriers: [],
    fields: [],
    operators: [],
    actions: [],
    source_platforms: [],
    filters: {
      country_iso_code: null,
      platform: null,
      campaign: null,
      triggers: {
        action: null,
        carrier: null,
        factor: null,
      },
      source_platform: 'v1',
      name: null,
    },

    currentPage: 1,
    perPage: Number(window.localStorage.getItem('per-page')) || 10,
    totalRows: 0,
    perPageConfig: [
      {value: 10, text: "Per page: 10"},
      {value: 20, text: "Per page: 20"},
      {value: 30, text: "Per page: 30"},
      {value: 50, text: "Per page: 50"},
      {value: 100, text: "Per page: 100"},
    ],

    tableFields: [
      {
        key: 'name',
        sortable: false,
        label: 'Name',
      },
      {
        key: 'country_name',
        sortable: false,
        label: 'Country',
      },
      {
        key: 'platform_name',
        sortable: false,
        label: 'Platform',
      },
      {
        key: 'campaign_name',
        sortable: false,
        label: 'Campaign',
      },
      {
        key: 'triggers',
        sortable: false,
        label: 'Triggers',
      },
      {
        key: 'status',
        sortable: false,
        label: 'Status'
      },
      {
        key: 'options',
        sortable: false,
        label: 'Options'
      },
    ],

    monitor: {
      name: null,
      country_iso_code: null,
      platform: null,
      campaign: null,
      triggers: [],
      source_platform: 'v1',
    },

    errors: {
      name: {state: null, message: null},
      country_iso_code: {state: null, message: null},
      platform: {state: null, message: null},
      campaign: {state: null, message: null},
      triggers: {state: null, message: null},
      source_platform: {state: null, message: null}
    },

    removeID: null,
    monitorLogs: [],
    loading: false
  }),

  watch: {
    currentPage: function () {
      this.loadMonitors();
    },

    perPage: function () {
      window.localStorage.setItem('per-page', this.perPage.toString());
      this.loadData();
    }
  },

  mounted: function () {
    API.get('/advertising/dropdown/source-platforms').then(data => this.source_platforms = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));
    API.get('/advertising/dropdown/platforms').then(data => this.platforms = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));

    API.get('/advertising/dropdown/countries').then(data => this.countries = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));

    API.get('/advertising/dropdown/fields').then(data => this.fields = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));
    API.get('/advertising/dropdown/operators').then(data => this.operators = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));
    API.get('/advertising/dropdown/actions').then(data => this.actions = data.data.map(item => {
      return {value: item.id, text: item.name};
    }));

    this.loadMonitors();
  },

  methods: {
    loadMonitors: function () {
      this.loading = true;
      API.post(`/advertising/monitor-list?page=${this.currentPage}&per-page=${this.perPage}`, this.filters).then(data => {
        this.monitors = data.data;
        this.totalRows = Number(data.headers['x-total-rows']) || 0;
        this.loading = false;
      }).catch(e => {
        this.error = e.message;
        this.loading = false;
      });
    },

    loadCarriers: function (isoCode) {
      API.get(`/advertising/dropdown/carriers?country_iso_code=${isoCode || this.monitor.country_iso_code}`)
          .then(data => this.carriers = data.data.map(carrier => {
            return carrier.name
          }));
    },

    loadCampaigns: function (platform) {
      API.get(`/advertising/dropdown/campaigns?platform_id=${platform || this.monitor.platform}`)
          .then(data => this.campaigns = data.data.map(carrier => {
            return {value: carrier.id, text: carrier.name}
          }));
    },

    remove: function (id) {
      this.removeID = id;
      this.showModal();
    },

    edit: function (monitor) {
      this.monitor = monitor;
      this.loadCampaigns();
      this.loadCarriers();
      this.flushErrors();

      this.form = true;
    },

    run: function (monitor) {
      API.put(`/advertising/run-monitor/${monitor._id}`).then(() => this.loadMonitors());
    },

    pause: function (monitor) {
      API.put(`/advertising/pause-monitor/${monitor._id}`).then(() => this.loadMonitors());
    },

    logs: function (monitor) {
      API.get(`/advertising/monitor-logs/${monitor['_id']}`).then(data => {
        this.monitorLogs = data.data;
        this.$refs.logsModalRef.show();
      });
    },

    copy: function (monitor) {
      const copyMonitor = Object.assign({}, monitor);
      copyMonitor._id = null;
      copyMonitor.name += '_COPY';
      this.monitor = copyMonitor;
      this.form = true;
    },

    showModal() {
      this.$refs.removeModalRef.show()
    },
    hideModal() {
      this.removeID = false;
      this.$refs.removeModalRef.hide()
    },

    doRemove: async function () {
      await API.delete(`/advertising/monitor/${this.removeID}`);
      await this.loadMonitors();
      this.hideModal();
    },

    flushErrors: function () {
      this.errors.name = {state: null, message: null};
      this.errors.country_iso_code = {state: null, message: null};
      this.errors.platform = {state: null, message: null};
      this.errors.campaign = {state: null, message: null};
      this.errors.triggers = {state: null, message: null};
    },

    saveMonitor: function () {
      this.processing = true;

      this.flushErrors();

      const request = this.monitor._id ? API.put(`/advertising/monitor/${this.monitor._id}`, this.monitor) : API.post('/advertising/monitor', this.monitor);

      request.then(() => {
        this.loadMonitors();
        this.monitor = {};
        this.processing = false;
        this.form = false;
      }).catch(e => {
        this.processing = false;
        if (e.response.status === 422) {
          e.response.data.forEach((error) => {
            this.errors[error.param].message = error.msg;
            this.errors[error.param].state = false;
          });
        } else {
          alert('Something went wrong =(');
        }
      });
    },

    cancelEdit: function () {
      this.flushErrors();
      this.form = false;
      this.monitor = {
        triggers: [],
      };
    },

    addTrigger: function () {
      this.monitor.triggers.push({
        carrier: [],
        carrier_match: 'all',
        with_alert: false,
        field: null,
        operator: null,
        value: null,
        action: null,
      })
    },

    removeTrigger: function (index) {
      this.monitor.triggers.splice(index, 1);
    },
  }
}
</script>